import React from 'react';

export default function QuestionItem({ title }) {
  // Format the question: capitalize first letter and add question mark if needed
  const formatQuestion = text => {
    if (!text) return '';

    // Capitalize the first letter
    let formattedText = text.charAt(0).toUpperCase() + text.slice(1);

    // Add question mark if not already present
    if (!formattedText.endsWith('?')) {
      formattedText += '?';
    }

    return formattedText;
  };

  return <div className="question-item">{formatQuestion(title)}</div>;
}
