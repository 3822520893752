import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import cx from 'classnames';

import DeletePostModal from '../../pages/Dashboard/DeletePostModal';
import MovePostModal from '../../pages/Dashboard/MovePostModal';
import AddPostUrlModal from '../../pages/PostBuilder/ComingSoonModal';
import UpdatePostStatusModal from '../../pages/Dashboard/UpdateStatusModel';

import { getCategory, categories } from './_utils';

const DashboardItemMenu = ({
  category = categories.keyword,
  name = '',
  id = '',
  status = '',
  callback = () => {},
}) => {
  const user = useSelector(state => state.auth.user);
  const history = useHistory();
  const { project_id } = useParams();

  const [openDeleteModal, showDeleteModal] = useState(false);
  const [openMoveModal, showMoveModal] = useState(false);
  const [modalOptions, setModalOptions] = useState({});
  const [isAddPostUrlModalVisible, showAddPostUrlModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);

  function closeDeleteModal(deleted) {
    if (deleted) {
      callback();
    }
    showDeleteModal(false);
  }

  function closeMoveModal(moved) {
    if (moved) {
      callback();
    }
    showMoveModal(false);
  }

  function closeAddPostUrlModal() {
    showAddPostUrlModal(false);
  }

  function addPostUrl() {
    if (modalOptions && modalOptions.type === 'domain') {
      history.push(`/account`);
    } else {
      history.push(`/project/${project_id}/post-builder/${id}`);
    }
    showAddPostUrlModal(false);
  }

  return (
    <Fragment>
      <DeletePostModal
        id={id}
        projectId={project_id}
        name={name}
        category={getCategory(category)}
        open={openDeleteModal}
        setOpen={closeDeleteModal}
      />
      <MovePostModal
        id={id}
        projectId={project_id}
        name={name}
        category={getCategory(category)}
        open={openMoveModal}
        setOpen={closeMoveModal}
      />
      <AddPostUrlModal
        name={modalOptions.name}
        detail={modalOptions.detail}
        opened={isAddPostUrlModalVisible}
        close={closeAddPostUrlModal}
        action={addPostUrl}
      />
      <UpdatePostStatusModal
        postId={id}
        projectId={project_id}
        category={'post'}
        open={openStatusModal}
        setOpen={setOpenStatusModal}
        onUpdated={() => {
          setOpenStatusModal(false);
          callback();
        }}
        currentStatus={status}
      />
      <Menu
        as="div"
        className="static inline-block text-left z-50"
        onPointerOver={e => e.stopPropagation()}
      >
        <div>
          <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
            <span className="sr-only">Open options</span>
            {!user.is_owner &&
            !user.is_manager &&
            !user.is_editor &&
            (category === 0 || category === 2 || category === 3) ? (
              <></>
            ) : (
              <DotsVerticalIcon className="h-4 w-4" aria-hidden="true" />
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {category === categories.post && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        history.push(
                          `/project/${project_id}/post-preview/${id}`
                        );
                      }}
                      className={cx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Preview
                    </button>
                  )}
                </Menu.Item>
              )}
              {category === categories.post && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        history.push(
                          `/project/${project_id}/keyword-research-new/${id}`,
                          { page: 'analyzer' }
                        );
                      }}
                      className={cx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Analyzer
                    </button>
                  )}
                </Menu.Item>
              )}
              {(user.is_owner || user.is_manager) && (
                <Fragment>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          showMoveModal(true);
                        }}
                        className={cx(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block w-full text-left px-4 py-2 text-sm'
                        )}
                      >
                        Move
                      </button>
                    )}
                  </Menu.Item>
                  {category === categories.post && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setOpenStatusModal(true);
                          }}
                          className={cx(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block w-full text-left px-4 py-2 text-sm'
                          )}
                        >
                          Status
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </Fragment>
              )}
              {(user.is_owner || user.is_manager || user.is_editor) && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        showDeleteModal(true);
                      }}
                      className={cx(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Delete
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  );
};

export default DashboardItemMenu;
