import React from 'react';
import { formatNumber } from '../../utils/common';

export default function KeywordItem({ keyword, keywordData, onclick }) {
  // Use the keywordData object if available, otherwise fallback to keyword
  // This ensures backward compatibility with old code that might just pass a string
  const data =
    keywordData || (typeof keyword === 'object' ? keyword : { keyword });

  function getValue(val, isCompetition = false) {
    if (val == null) return <span>No results</span>;
    // For competition values, multiply by 100 to convert from decimal to percentage
    return formatNumber(isCompetition ? val * 100 : val);
  }

  return (
    <div
      className={`keyword-ideas__item ${onclick ? 'cursor-pointer' : ''}`}
      onClick={e => onclick && onclick(e, data.keyword)}
    >
      <p className="title">{data.keyword}</p>
      <div className="keyword-value">
        <div className="keyword-value__item">
          <span>Volume:&nbsp;</span>
          {getValue(data.search_volume)}
        </div>
        <div className="keyword-value__item">
          <span>Competition:&nbsp;</span>
          {getValue(data.competition, true)}
        </div>
        <div className="keyword-value__item">
          <span>Difficulty:&nbsp;</span>
          {getValue(data.keyword_difficulty || data.difficulty)}
        </div>
        <div className="keyword-value__item">
          <span>Results:&nbsp;</span>
          {getValue(data.results_count || data.number_of_results)}
        </div>
      </div>
    </div>
  );
}
