import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useAlert } from 'react-alert';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusSmIcon,
  ClipboardCopyIcon,
  EyeIcon,
} from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';

import ShowButton from './keywords-tab/show-button';
import ComponentTip from './ComponentTip';
import useSentData from '../services/useSentData';
import { track } from '../services/track';
import { isolateError } from '../utils/api';
import { formatNumber } from '../utils/common';

import {
  addRelatedKeywords,
  addTopQuestions,
} from '../store/actions/post_builder.actions';
import SemrushLogo from '../styles/images/logo-semrush.svg';
import KeywordAnalyzerModal from './keywords-tab/analyzer-modal';

export default function KeywordsTab({
  phrase,
  topics,
  topPosts = [],
  editorRef,
}) {
  const request = useSentData();
  let { id, project_id } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  const [openRelated, setOpenRelated] = useState(true);
  const [openSecondaryKeywords, setOpenSecondaryKeywords] = useState(true);
  const [openQuestions, setOpenQuestions] = useState(true);
  const [getQuestions, setGetQuestions] = useState(false);
  const [getRelatedKeywords, setGetRelatedKeywords] = useState(false);
  const [openPosts, setOpenPosts] = useState(true);
  const [limitQuestions, setLimitQuestions] = useState(10);
  const [limitRelatedKeywords, setLimitRelatedKeywords] = useState(10);
  const [openKeywordAnalyzer, setOpenKeywordAnalyzer] = useState(false);

  const relatedKeywords =
    useSelector(state => state.post.relatedKeywords) || [];
  const secondaryKeywords =
    useSelector(state => state.post.secondaryKeywords) || [];
  const topQuestions = useSelector(state => state.post.topQuestions) || [];
  const postId = useSelector(state => state.post.id);
  const isSemrushConnected = useSelector(
    state => state.auth.user.isSemrushConnected
  );

  if (!id && postId) {
    id = postId;
  }

  const renderRelatedKeywords = () => {
    const showMoreRelatedKeywords = () => {
      setGetRelatedKeywords(true);
      track('Blog post creator', { Clicked: 'more related keywords' });
      request
        .send('/api/semrush/related', {
          limit: isSemrushConnected
            ? limitRelatedKeywords
            : 10 - relatedKeywords.length,
          keyword_phrase: phrase,
        })
        .then(data => {
          setGetRelatedKeywords(false);
          if (!data.isAxiosError) {
            const filteredData = data?.filter(
              d => !relatedKeywords.includes(d)
            );
            if (!!filteredData.length) {
              const r = isSemrushConnected
                ? [...relatedKeywords, ...filteredData]
                : [...relatedKeywords, ...filteredData].splice(0, 10);
              dispatch(addRelatedKeywords(r));
              if (isSemrushConnected) {
                setLimitRelatedKeywords(limitRelatedKeywords + 5);
              }
            } else {
              alert.info("We don't have more keywords to show");
            }
          } else {
            alert.error(isolateError(data));
          }
        });
    };

    const showLessRelatedKeywords = () => {
      const r = relatedKeywords.slice(0, 10);
      dispatch(addRelatedKeywords(r));
      setLimitRelatedKeywords(10);
    };

    const showButtons =
      relatedKeywords !== undefined &&
      relatedKeywords !== null &&
      relatedKeywords.length > 0;
    const isShowmoreDisabled =
      getRelatedKeywords ||
      (relatedKeywords.length >= 10 && !isSemrushConnected);

    const renderLabel = () => {
      return (
        <div className="flex items-center gap-x-2">
          <ReactTooltip
            id={'semrush-connected'}
            place={'bottom'}
            type="dark"
            effect="float"
            border={false}
          />
          <p>Related keywords</p>
          {/* {isSemrushConnected && (
            <ComponentTip text="Semrush connected" data-for="semrush-connected">
              <img src={SemrushLogo} className="h-8" alt="Semrush" />
            </ComponentTip>
          )} */}
        </div>
      );
    };

    return (
      <Fragment>
        {openRelated ? (
          <div className="mb-4">
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenRelated(false)}
            >
              {renderLabel()}
              <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            {relatedKeywords.length <= 0 && (
              <div className="py-3 px-5 text-sm w-60 sm:w-116">
                <p>No data available at this time.</p>
              </div>
            )}
            <ul role="list" className="flex-1 divide-y divide-gray-200">
              {relatedKeywords.map((keyword, i) => (
                <li key={i} className="py-4 mr-5">
                  <div className="flex items-center space-x-4">
                    <div className="flex min-w-0">
                      <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900">
                        {keyword}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <Tooltip title="Insert in editor" arrow>
                        <button
                          onClick={() => {
                            if (editorRef?.current?.editor) {
                              editorRef?.current?.editor?.insertContent(
                                keyword
                              );
                            }
                          }}
                          className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          <PlusSmIcon
                            className="h-4 w-4 text-gray-700"
                            aria-hidden="true"
                          />
                        </button>
                      </Tooltip>
                      <CopyButton text={keyword} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/* {showButtons && (
              <div className="my-6 flex px-6 gap-x-6">
                <ReactTooltip
                  id={'navigate_to_integrations'}
                  place={'bottom'}
                  type="dark"
                  effect="solid"
                  border={false}
                  className="w-48"
                />
                <div className="flex-1">
                  <ShowButton
                    disabled={
                      getRelatedKeywords || relatedKeywords.length <= 10
                    }
                    loading={getRelatedKeywords}
                    label="Show less"
                    onClick={showLessRelatedKeywords}
                    secondary
                    hidden={relatedKeywords.length <= 10}
                  />
                </div>
                <div className="flex-1">
                  <ComponentTip
                    text={
                      isShowmoreDisabled
                        ? 'Go to Integrations page and connect to Semrush to be able to show more.'
                        : ''
                    }
                    data-for={'navigate_to_integrations'}
                  >
                    <ShowButton
                      disabled={isShowmoreDisabled}
                      loading={getRelatedKeywords}
                      label="Show more"
                      onClick={showMoreRelatedKeywords}
                    />
                  </ComponentTip>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div
            className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
            role="alert"
            onClick={() => setOpenRelated(true)}
          >
            {renderLabel()}
            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        )}
      </Fragment>
    );
  };

  const renderSecondaryKeyWords = () => {
    const renderLabel = () => {
      return (
        <div className="flex items-center gap-x-2">
          <p>Secondary keywords</p>
        </div>
      );
    };

    return (
      <Fragment>
        {openSecondaryKeywords ? (
          <div className="mb-4">
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenSecondaryKeywords(false)}
            >
              {renderLabel()}
              <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            {!secondaryKeywords.length && (
              <div className="py-3 px-5 text-sm w-60 sm:w-116">
                <p>No data available at this time.</p>
              </div>
            )}
            <ul role="list" className="flex-1 divide-y divide-gray-200">
              {!!secondaryKeywords.length &&
                secondaryKeywords.map((keyword, i) => (
                  <li key={i} className="py-4 mr-5">
                    <div className="flex items-center space-x-4">
                      <div className="flex min-w-0">
                        <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900">
                          {keyword}
                        </p>
                      </div>
                      <div className="flex items-center gap-4">
                        <Tooltip title="Insert in editor" arrow>
                          <button
                            onClick={() => {
                              if (editorRef?.current?.editor) {
                                editorRef?.current?.editor?.insertContent(
                                  keyword
                                );
                              }
                            }}
                            className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                          >
                            <PlusSmIcon
                              className="h-4 w-4 text-gray-700"
                              aria-hidden="true"
                            />
                          </button>
                        </Tooltip>
                        <CopyButton text={keyword} />
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          <div
            className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
            role="alert"
            onClick={() => setOpenSecondaryKeywords(true)}
          >
            {renderLabel()}
            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        )}
      </Fragment>
    );
  };

  const renderTopQuestions = () => {
    const showMoreQuestions = () => {
      setGetQuestions(true);
      track('Blog post creator', { Clicked: 'more questions' });
      request
        .send('/api/semrush/questions', {
          limit: isSemrushConnected ? limitQuestions : 10 - topQuestions.length,
          keyword_phrase: phrase,
        })
        .then(data => {
          setGetQuestions(false);
          if (!data.isAxiosError) {
            const filteredData = data?.filter(d => !topQuestions.includes(d));
            if (!!filteredData.length) {
              const q = isSemrushConnected
                ? [...topQuestions, ...filteredData]
                : [...topQuestions, ...filteredData].splice(0, 10);
              dispatch(addTopQuestions(q));
              if (isSemrushConnected) {
                setLimitQuestions(limitQuestions + 5);
              }
            } else {
              alert.info("We don't have more questions to show");
            }
          } else {
            alert.error(isolateError(data));
          }
        });
    };

    const showLessQuestions = () => {
      const q = topQuestions.slice(0, 10);
      dispatch(addTopQuestions(q));
      setLimitQuestions(10);
    };

    const showButtons =
      topQuestions !== undefined &&
      topQuestions !== null &&
      topQuestions.length > 0;
    const isShowmoreDisabled =
      getQuestions || (topQuestions.length >= 10 && !isSemrushConnected);

    const renderLabel = () => {
      return (
        <div className="flex items-center gap-x-2">
          <ReactTooltip
            id="semrush-connected"
            place={'bottom'}
            type="dark"
            effect="float"
            border={false}
          />
          <p>Top questions</p>
          {/* {isSemrushConnected && (
            <ComponentTip text="Semrush connected" data-for="semrush-connected">
              <img src={SemrushLogo} className="h-8" alt="Semrush" />
            </ComponentTip>
          )} */}
        </div>
      );
    };

    return (
      <Fragment>
        {openQuestions ? (
          <div className="mb-4">
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenQuestions(false)}
            >
              {renderLabel()}
              <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
            </div>
            {topQuestions.length <= 0 && (
              <div className="py-3 px-5 text-sm w-60 sm:w-116">
                <p>No data available at this time.</p>
              </div>
            )}
            <ul role="list" className="flex-1 divide-y divide-gray-200">
              {topQuestions.map((question, i) => (
                <li key={i} className="py-4 mr-5">
                  <div className="flex items-center space-x-4">
                    <div className="flex min-w-0">
                      <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900">
                        {question}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <Tooltip title="Insert in editor" arrow>
                        <button
                          onClick={() => {
                            if (editorRef?.current?.editor) {
                              editorRef?.current?.editor?.insertContent(
                                question
                              );
                            }
                          }}
                          className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                          <PlusSmIcon
                            className="h-4 w-4 text-gray-700"
                            aria-hidden="true"
                          />
                        </button>
                      </Tooltip>
                      <CopyButton text={question} />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {/* {showButtons && (
              <div className="my-6 flex px-6 gap-x-6">
                <ReactTooltip
                  id={'navigate_to_integrations'}
                  place={'bottom'}
                  type="dark"
                  effect="solid"
                  border={false}
                  className="w-48"
                />
                <div className="flex-1">
                  <ShowButton
                    disabled={getQuestions || topQuestions.length <= 10}
                    loading={getQuestions}
                    label="Show less"
                    onClick={showLessQuestions}
                    secondary
                    hidden={topQuestions.length <= 10}
                  />
                </div>
                <div className="flex-1">
                  <ComponentTip
                    text={
                      isShowmoreDisabled
                        ? 'Go to Integrations page and connect to Semrush to be able to show more'
                        : ''
                    }
                    data-for={'navigate_to_integrations'}
                  >
                    <ShowButton
                      disabled={isShowmoreDisabled}
                      loading={getQuestions}
                      label="Show more"
                      onClick={showMoreQuestions}
                    />
                  </ComponentTip>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div
            className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
            role="alert"
            onClick={() => setOpenQuestions(true)}
          >
            {renderLabel()}
            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
          </div>
        )}
      </Fragment>
    );
  };

  const onClickCopy = text => () => {
    navigator.clipboard.writeText(text);
    alert.success('Successfully copied to clipboard!');
  };

  const CopyButton = ({ text }) => (
    <Tooltip title="Copy" arrow>
      <button
        onClick={onClickCopy(text)}
        className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
      >
        <ClipboardCopyIcon
          className="h-4 w-4 text-gray-700"
          aria-hidden="true"
        />
      </button>
    </Tooltip>
  );

  return (
    <div className="overflow-y-auto">
      <div className="flow-root mt-8">
        <div className="w-80 sm:w-128">
          <div className="mx-5 font-semibold bg-white flex justify-between text-gray-900">
            Primary keyword
          </div>
          <div className="py-4 px-5">
            <div className="flex items-center space-x-4">
              <div className="flex min-w-0">
                <p className="w-80 sm:w-108 text-sm font-medium text-gray-900">
                  {phrase}
                </p>
              </div>
              <div className="flex items-center gap-4">
                {/* <Tooltip title="Keyword Analyzer" arrow>
                  <button
                    onClick={() => {
                      setOpenKeywordAnalyzer(true);
                    }}
                    className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <EyeIcon
                      className="h-4 w-4 text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </Tooltip> */}
                <Tooltip title="Insert in editor" arrow>
                  <button
                    onClick={() => {
                      if (editorRef?.current?.editor) {
                        editorRef?.current?.editor?.insertContent(phrase);
                      }
                    }}
                    className="inline-flex items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    <PlusSmIcon
                      className="h-4 w-4 text-gray-700"
                      aria-hidden="true"
                    />
                  </button>
                </Tooltip>
                <CopyButton text={phrase} />
              </div>
            </div>
          </div>
          {renderSecondaryKeyWords()}
          {renderRelatedKeywords()}
          {renderTopQuestions()}
          <KeywordAnalyzerModal
            phrase={phrase}
            projectId={project_id}
            isOpen={openKeywordAnalyzer}
            onClose={() => setOpenKeywordAnalyzer(false)}
          />
          {openPosts ? (
            <div className="mb-4">
              <div
                className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
                role="alert"
                onClick={() => setOpenPosts(false)}
              >
                Top posts
                <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
              </div>
              {topPosts.length <= 0 && (
                <div className="py-3 px-5 text-sm w-60 sm:w-116">
                  <p>No data available at this time.</p>
                </div>
              )}
              <ul role="list" className="flex-1 divide-y divide-gray-200">
                {topPosts.map((post, i) => (
                  <li key={i} className="py-4">
                    <div className="flex items-center space-x-4">
                      <div className="min-w-0">
                        <p className="mx-5 w-80 sm:w-100 text-sm font-medium text-gray-900 overflow-hidden whitespace-nowrap overflow-ellipsis">
                          {i + 1}.
                          <a
                            href={post.Url}
                            target="_blank"
                            className="text-purple-500 hover:text-purple-700 mx-2 text-sm w-40 font-medium"
                            rel="noreferrer"
                          >
                            {post.Url}
                          </a>
                        </p>
                        <p className="pl-10 text-xs text-gray-900">
                          {post.Domain}
                        </p>
                      </div>
                      <Tooltip title="Estimated monthly traffic" arrow>
                        <div>
                          <p className="inline-flex mr-5 items-center mx-5 text-xs text-gray-400">
                            EMT
                          </p>
                          <p className="mx-5 text-xs text-gray-700">
                            {post.EMT ? formatNumber(post.EMT, 1) : 'n/a'}
                          </p>
                        </div>
                      </Tooltip>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div
              className="p-5 cursor-pointer font-semibold bg-white hover:bg-gray-100 hover:bg-opacity-50 flex justify-between text-gray-900"
              role="alert"
              onClick={() => setOpenPosts(true)}
            >
              Top posts
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
