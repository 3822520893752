import React, { Fragment } from 'react';
import Modal from 'react-modal';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { isolateError } from '../../utils/api';
import useSentData from '../../services/useSentData';
import { processHTML } from '../../utils/processHTML';
import { Link } from 'react-router-dom';
import './QualityConfirmationModal.scss';
import closemark from '../../styles/images/close-outline.svg';
import checkmark from '../../styles/images/checkmark-outline.svg';
import novaRobotImgSrc from '../../styles/images/generate-first-draft/nova-robot.png';
import stopSignImgSrc from '../../styles/images/generate-first-draft/stop-sign.png';
import {
  addSecondaryKeywords,
  setAIWritingToolSelected,
} from '../../store/actions/post_builder.actions';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { useDispatch, useSelector } from 'react-redux';
import da from 'react-timeago/lib/language-strings/da';
import LoadingIcon from '../../components/LoadingIcon';

export default function QualitytConfirmationModal({
  id,
  project_id,
  data,
  opened,
  close,
  selectedItem,
}) {
  const request = useSentData();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  // Get user from Redux store
  const userId = useSelector(state => state.auth.user.id);

  function updateData() {
    // Get user from either data or Redux state
    const user = data.user || userId;

    if (!user) {
      console.warn(
        'No user ID found for request. This might cause API errors.'
      );
    }

    // For keyword analyzer
    if (data.category === 1) {
      // Add a basic body structure similar to other categories
      let body = [
        {
          type: 'Title',
          state: {
            text: data.phrase || '',
          },
        },
        {
          type: 'HeroImage',
          state: {
            file: null,
          },
        },
        {
          type: 'Body',
          state: {
            id: null,
            text: '',
          },
        },
      ];

      const updatedData = {
        phrase: data.phrase,
        analysis_data: data.analysis_data,
        category: data.category,
        body: body,
        user: user,
      };
      return updatedData;
    }
    // For title generator or outline generator
    else if (data.category === 2 || data.category === 3) {
      let body = [
        {
          type: 'Title',
          state: {
            text: data.category === 2 ? selectedItem : '',
          },
        },
        {
          type: 'HeroImage',
          state: {
            file: null,
          },
        },
        {
          type: 'Body',
          state: {
            id: data.category === 2 ? null : selectedItem.id,
            text: data.category === 2 ? '' : processHTML(selectedItem.html),
          },
        },
      ];
      return { ...data, body: body, user: user };
    }
    // Default case
    else {
      console.log(
        'No specific data transformation for category:',
        data.category
      );
      return { ...data, user: user };
    }
  }

  function createPost() {
    // Prepare data and validate
    const updatedData = updateData();

    // Check if user ID is missing - prevent API call that will fail
    if (!updatedData.user) {
      console.error('Cannot create/update post: User ID is missing');
      alert.error(
        'User ID is required but missing. Please try again or contact support.'
      );
      return;
    }

    if (id) {
      if (data.category === 2 || data.category === 3) {
        let url = project_id
          ? `/api/projects/${project_id}/posts`
          : '/api/posts';

        if (data.category === 2) {
          url += `/${id}/title-generator`;
        } else {
          url += `/${id}/outline-generator`;
        }

        request.send(url, updatedData, 'put').then(resData => {
          if (!resData.isAxiosError) {
            // Ensure we have a valid ID before proceeding
            const postData = { ...resData, id: id };
            goToPostBuilder(postData);
          } else {
            // Log more details about the error
            if (resData.response) {
              console.error('Error details:', {
                status: resData.response.status,
                data: resData.response.data,
              });
            }
            alert.error(isolateError(resData));
          }
        });
      } else {
        request
          .send(
            `/api/projects/${project_id}/posts/${id}/keyword-analyzer`,
            updatedData,
            'put'
          )
          .then(resData => {
            if (!resData.isAxiosError) {
              // Ensure we have a valid ID before proceeding
              const postData = { ...resData, id: id };
              goToPostBuilder(postData);
            } else {
              // Log more details about the error
              if (resData.response) {
                console.error('Error details:', {
                  status: resData.response.status,
                  data: resData.response.data,
                });
              }
              alert.error(isolateError(resData));
            }
          });
      }
    } else {
      const url = project_id
        ? `/api/projects/${project_id}/posts`
        : '/api/posts';

      request.send(url, updatedData, 'post').then(resData => {
        if (!resData.isAxiosError) {
          // Make sure we pass the response data containing the new post ID
          goToPostBuilder(resData);
        } else {
          // Log more details about the error
          if (resData.response) {
            console.error('Error details:', {
              status: resData.response.status,
              data: resData.response.data,
            });
          }
          alert.error(isolateError(resData));
        }
      });
    }
  }

  const goToPostBuilder = async postData => {
    if (!postData.id) {
      alert.error('Could not navigate to the post. Missing post ID.');
      return;
    }

    let res = await request
      .send('/api/generate-keywords', {
        phrase: postData.phrase,
      })
      .catch(err => console.error('Error generating keywords:', err));

    const keywords = res?.content || '';

    try {
      await request.send(
        `/api/projects/${project_id}/posts/${postData.id}`,
        { topics: keywords ? keywords.split(',') : null },
        'put'
      );

      dispatch(setAIWritingToolSelected('title'));

      history.push({
        pathname: `/project/${project_id}/post-builder/${postData.id}`,
        state: { id: postData.id },
      });

      if (keywords) {
        const additionKeywords = keywords.split(',').map((item, index) => ({
          id: new Date().getTime() + index,
          text: item,
          selected: false,
        }));
        sessionStorage.setItem(
          `${ADDITION_KEYWORDS_KEY}_${postData.id}`,
          JSON.stringify(additionKeywords)
        );
        dispatch(addSecondaryKeywords(keywords.split(',')));
      }
    } catch (err) {
      console.error('Error updating post with keywords:', err);
      alert.error('Error saving keywords to post.');
    }
  };

  const Content = ({
    renderImg = () => null,
    img,
    motiText,
    qualityText,
    suggestionText,
    renderSuggestionText = () => null,
    suggMore,
    warning,
    cancelBtnLabel,
    func,
  }) => (
    <div className={request.loading && 'animate-pulse'}>
      {img ? <img src={img} alt="checkmark" /> : renderImg()}
      <h1>{motiText}</h1>
      <h3>{qualityText}</h3>
      {suggestionText ? <p>{suggestionText}</p> : renderSuggestionText()}
      {warning && <p className="warning">{warning}</p>}
      {suggMore && <p className="mt-4">{suggMore}</p>}
      <div className="buttons-wrapper">
        <button
          className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={close}
          disabled={request.loading}
        >
          {cancelBtnLabel || 'Cancel'}
        </button>
        <button
          disabled={request.loading}
          className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
          onClick={createPost}
        >
          {request.loading && <LoadingIcon />}
          <span>Continue</span>
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      className="quality-confirmation-modal"
      isOpen={opened}
      ariaHideApp={false}
    >
      <div>
        {data &&
        data.analysis_data &&
        ((data.analysis_data.top_questions &&
          data.analysis_data.top_questions.length > 0) ||
          (data.analysis_data.top_related &&
            data.analysis_data.top_related.length > 0)) ? (
          <Content
            renderImg={() => (
              <div className="pl-14">
                <img src={novaRobotImgSrc} alt="Good" />
              </div>
            )}
            motiText="Great!"
            qualityText="Based on this topic, the output Bramework's AI generates should be good quality."
          />
        ) : (
          <div className={request.loading && 'animate-pulse'}>
            <div>
              <img src={stopSignImgSrc} alt="Bad" className="w-48" />
            </div>
            <h1>Wait!</h1>
            <h3>
              Bramework's AI may not be able to suggest keywords or generate
              high quality content for this topic.
            </h3>
            <p>
              Try shortening your topic phrase, or find a keyword that gets more
              searches using the
              <br />
              <Link
                className="text-purple-600"
                to={{
                  pathname: `/project/${project_id}/keyword-research-new`,
                  state: 'analyzer',
                }}
                onClick={close}
              >
                Keyword Analyzer.
              </Link>
            </p>
            <div className="buttons-wrapper">
              <button
                className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={close}
                disabled={request.loading}
              >
                Back
              </button>
              <button
                disabled={request.loading}
                className="mt-6 inline-flex items-center px-4 py-2 md:px-6 md:py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                onClick={createPost}
              >
                {request.loading && <LoadingIcon />}
                <span>Continue</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
