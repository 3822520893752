import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useSentData from '../services/useSentData';
import { isolateError } from '../utils/api';
import { track } from '../services/track';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import PageHeading from '../components/PageHeading';
import FormInput from '../components/form/FormInput';
import FormTextarea from '../components/form/FormTextarea';
import LoadingIcon from '../components/LoadingIcon';
import { Tooltip } from '@material-ui/core';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { InformationCircleIcon } from '@heroicons/react/solid';
import Select from '../components/form/FormSelect';
import { TONES } from '../utils/common';
import { getId, LABELS } from '../pages/GenerateFirstDraft/utils';
import FormContainer from '../pages/GenerateFirstDraft/FormContainer';
import FormLabel from '../pages/GenerateFirstDraft/FormLabel';
import NovaRobot from '../styles/images/generate-first-draft/nova-robot.png';

const SocialMediaContentGenerator = () => {
  const { project_id, id } = useParams();
  const history = useHistory();
  const request = useSentData();
  const alert = useAlert();
  const [contentUrl, setContentUrl] = useState('');
  const [contentText, setContentText] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [includeYoutubeTitle, setIncludeYoutubeTitle] = useState(true);
  const [includeYoutubeDescription, setIncludeYoutubeDescription] = useState(
    true
  );
  const [targetAudience, setTargetAudience] = useState('');
  const [includeUrl, setIncludeUrl] = useState(true);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [inputType, setInputType] = useState('url');
  const [generations, setGenerations] = useState([]);
  const [selectedPlatformFilter, setSelectedPlatformFilter] = useState('all');
  const [loadingType, setLoadingType] = useState('');
  const [instructions, setInstructions] = useState('');
  const [useBrandVoice, setUseBrandVoice] = useState(false);
  const [tone, setTone] = useState('informative');
  const [filteredPlatforms, setFilteredPlatforms] = useState([]);

  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;
  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values.";

  const handleUseBrandVoiceChange = checked => {
    if (!isDisabled) {
      setUseBrandVoice(checked);
    }
  };

  const platforms = [
    { value: 'facebook', label: 'Facebook Post' },
    { value: 'twitter', label: 'Twitter Post' },
    { value: 'instagram', label: 'Instagram Caption' },
    { value: 'youtube', label: 'YouTube Script' },
    { value: 'linkedin', label: 'LinkedIn Post' },
    { value: 'email', label: 'Email Newsletter' },
    { value: 'pinterest', label: 'Pinterest Pin' },
  ];

  // Variables for tracking likes
  const [generationLikes, setGenerationLikes] = useState({});

  // Use this effect once on component mount to fetch existing post if needed
  useEffect(() => {
    if (id) {
      fetchExistingPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (inputType === 'url') {
      setContentText('');
      setYoutubeUrl('');
    } else if (inputType === 'content') {
      setContentUrl('');
      setYoutubeUrl('');
    } else if (inputType === 'youtube') {
      setContentText('');
      setContentUrl('');
    }
  }, [inputType]);

  // This useEffect updates platform selection when inputType changes
  useEffect(() => {
    if (inputType === 'youtube') {
      // If 'YouTube' is in selected platforms, ensure it's not selected
      setSelectedPlatforms(prev => prev.filter(p => p !== 'youtube'));
    }
  }, [inputType]);

  // Add an effect to update filteredPlatforms when platforms or inputType changes
  useEffect(() => {
    const filtered =
      inputType === 'youtube'
        ? platforms.filter(platform => platform.value !== 'youtube')
        : platforms;
    setFilteredPlatforms(filtered);
  }, [inputType]);

  const fetchExistingPost = useCallback(() => {
    request
      .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
      .then(data => {
        if (!data.isAxiosError) {
          setGenerations(data.social_content || []);
        } else {
          alert.error(isolateError(data));
        }
      })
      .catch(error => {
        console.error('Error fetching post:', error);
      });
  }, [request, project_id, id, alert]);

  const onSubmit = useCallback(() => {
    track('Social Media Content Generator', { Clicked: 'generate' });
    setLoadingType('generator');
    request
      .send('/api/social-content-generator', {
        content_url: inputType === 'url' ? contentUrl : null,
        content_text: inputType === 'content' ? contentText : null,
        youtube_url: inputType === 'youtube' ? youtubeUrl : null,
        include_youtube_title: includeYoutubeTitle,
        include_youtube_description: includeYoutubeDescription,
        target_audience: targetAudience,
        include_url: inputType !== 'youtube' ? includeUrl : false,
        selected_platforms: selectedPlatforms,
        instructions: instructions,
        tone: tone,
        use_brand_voice: useBrandVoice,
        project_id,
        input_type: inputType,
      })
      .then(response => {
        if (!response.isAxiosError) {
          // Check for success flag in response
          if (response.success === false) {
            // Handle server-side error that was properly formatted
            alert.error(response.message || 'Failed to generate content');
            return;
          }

          // Safely handle the content object
          if (response && response.content) {
            try {
              const newGeneration = {
                generation_id: response.content.generation_id,
                created_at: response.content.created_at,
                like_status: response.content.like_status,
                output: response.content.output || {},
                platforms: response.content.output
                  ? Object.keys(response.content.output)
                  : [],
              };
              setGenerations(prevGenerations => [
                newGeneration,
                ...prevGenerations,
              ]);
            } catch (err) {
              console.error('Error processing response:', err);
              alert.error(
                'An error occurred while processing the response. Please try again.'
              );
            }
          } else {
            console.error('Response missing content object:', response);
            alert.error(
              'Received an invalid response from the server. Please try again.'
            );
          }
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => {
        console.error('Request error:', err);
        alert.error(err?.message || 'An error occurred. Please try again.');
      })
      .finally(() => setLoadingType(''));
  }, [
    contentUrl,
    contentText,
    youtubeUrl,
    includeYoutubeTitle,
    includeYoutubeDescription,
    targetAudience,
    includeUrl,
    selectedPlatforms,
    instructions,
    tone,
    useBrandVoice,
    project_id,
    inputType,
    request,
    alert,
  ]);

  function handleSave() {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/social-content-generator`;
      method = 'put';
    }
    setLoadingType('savePosts');

    // Prepare the data with correct keys before sending
    const payload = generations.map(gen => ({
      generation_id: gen.generation_id,
      created_at: gen.created_at,
      like_status: gen.like_status,
      content: gen.output,
    }));

    request
      .send(url, { category: 6, social_content: payload }, method)
      .then(response => {
        if (!response.isAxiosError) {
          history.push(`/project/${project_id}/social-content`);
        } else {
          alert.error(isolateError(response));
        }
      })
      .finally(() => setLoadingType(''));
  }

  const updateLikeStatus = useCallback(
    (generation_id, status) => {
      const url = `/api/like-social-content`;
      const method = 'post';
      const updatedData = { generation_id, status };

      request
        .send(url, updatedData, method)
        .then(response => {
          if (!response.isAxiosError) {
            const updatedGenerations = generations.map(generation =>
              generation.generation_id === generation_id
                ? { ...generation, like_status: [{ status }] }
                : generation
            );
            setGenerations(updatedGenerations);
          } else {
            alert.error(isolateError(response));
          }
        })
        .catch(err => alert.error(err.message));
    },
    [generations, request, alert]
  );

  const onDeleteGeneration = useCallback(
    generation_id => {
      return () => {
        const url = `/api/delete-social-content`;
        const method = 'post';
        const updatedData = { generation_id };

        request
          .send(url, updatedData, method)
          .then(response => {
            if (!response.isAxiosError) {
              setGenerations(prevGenerations =>
                prevGenerations.filter(
                  gen => gen.generation_id !== generation_id
                )
              );
              alert.success('Content deleted successfully!');
            } else {
              alert.error(isolateError(response));
            }
          })
          .catch(err => alert.error(err.message));
      };
    },
    [request, alert]
  );

  const copyToClipboard = content => {
    navigator.clipboard.writeText(content);
    alert.success('Content copied to clipboard!');
    track('Social Media Content', { Clicked: 'copy content' });
  };

  const filteredGenerations = generations.filter(
    gen =>
      selectedPlatformFilter === 'all' ||
      (gen.output && gen.output.hasOwnProperty(selectedPlatformFilter)) ||
      (selectedPlatformFilter === 'youtube' &&
        gen.output &&
        (gen.output.hasOwnProperty('youtube') ||
          gen.output.hasOwnProperty('youtube_title') ||
          gen.output.hasOwnProperty('youtube_description')))
  );

  // Get platforms for each generation with special handling for YouTube
  const getPlatformsForDisplay = generation => {
    if (selectedPlatformFilter === 'all') {
      return Object.entries(generation.output);
    } else if (selectedPlatformFilter === 'youtube') {
      // Get all YouTube related entries
      return Object.entries(generation.output).filter(
        ([platform]) =>
          platform === 'youtube' ||
          platform === 'youtube_title' ||
          platform === 'youtube_description'
      );
    } else {
      // Return just the selected platform
      return [
        [selectedPlatformFilter, generation.output[selectedPlatformFilter]],
      ];
    }
  };

  // URL validation
  const isValidUrl = url => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  // YouTube URL validation - Simplified to accept more formats
  const isValidYoutubeUrl = url => {
    if (!url || url.trim() === '') return false;

    // Basic check for youtube domain
    return url.includes('youtube.com/') || url.includes('youtu.be/');
  };

  // Validation logic
  const isFormValid = () => {
    // For YouTube URL, either YouTube content options or social platforms must be selected
    if (inputType === 'youtube') {
      const hasYoutubeContentSelected =
        includeYoutubeTitle || includeYoutubeDescription;
      const hasSocialPlatformsSelected = selectedPlatforms.length > 0;
      return (
        isValidYoutubeUrl(youtubeUrl.trim()) &&
        (hasYoutubeContentSelected || hasSocialPlatformsSelected)
      );
    }

    // For URL input, we need a valid URL and at least one platform
    if (inputType === 'url') {
      return isValidUrl(contentUrl.trim()) && selectedPlatforms.length > 0;
    }

    // For content text input, we need text and at least one platform
    return contentText.trim() !== '' && selectedPlatforms.length > 0;
  };

  const getValidationErrorMessage = () => {
    if (inputType === 'youtube') {
      if (!isValidYoutubeUrl(youtubeUrl.trim())) {
        return 'Please enter a valid YouTube URL';
      }

      const hasYoutubeContentSelected =
        includeYoutubeTitle || includeYoutubeDescription;
      const hasSocialPlatformsSelected = selectedPlatforms.length > 0;

      if (!hasYoutubeContentSelected && !hasSocialPlatformsSelected) {
        return 'Please select either YouTube content options or social media platforms';
      }
    } else if (inputType === 'url') {
      if (!isValidUrl(contentUrl.trim())) {
        return 'Please enter a valid URL';
      }
      if (selectedPlatforms.length === 0) {
        return 'Please select at least one social media platform';
      }
    } else if (contentText.trim() === '') {
      return 'Please enter some content text';
    } else if (selectedPlatforms.length === 0) {
      return 'Please select at least one social media platform';
    }

    return '';
  };

  const renderContent = content => {
    return (
      <ReactMarkdown className="prose prose-sm max-w-none">
        {content}
      </ReactMarkdown>
    );
  };

  const renderGenerationItem = useCallback(
    (generation, index) => {
      if (!generation || !generation.output) {
        return null;
      }

      const likeStatus =
        generation.like_status && generation.like_status[0]
          ? generation.like_status[0].status
          : null;

      return (
        <div
          key={generation.generation_id}
          className="bg-gray-50 p-4 rounded-lg"
        >
          <div className="flex justify-between items-center mb-2">
            <span className="text-xs text-gray-400">
              {moment(generation.created_at).fromNow()}
            </span>
            <div className="flex items-center">
              <Tooltip title="Like" arrow>
                <button
                  onClick={() => updateLikeStatus(generation.generation_id, 1)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-20"
                >
                  <ThumbUpIcon
                    className={`h-4 w-4 hover:text-green-500 ${
                      likeStatus === 1 ? 'text-green-500' : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Dislike" arrow>
                <button
                  onClick={() => updateLikeStatus(generation.generation_id, 2)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <ThumbDownIcon
                    className={`h-4 w-4 hover:text-red-500 ${
                      likeStatus === 2 ? 'text-red-500' : 'text-gray-500'
                    }`}
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Copy" arrow>
                <button
                  onClick={() =>
                    copyToClipboard(JSON.stringify(generation.output))
                  }
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-20"
                >
                  <DocumentDuplicateIcon
                    className="h-4 w-4 text-gray-500"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Delete" arrow>
                <button
                  onClick={onDeleteGeneration(generation.generation_id)}
                  className="inline-flex ml-3 items-center p-1 border bg-gray-100 border-transparent rounded-full shadow-sm text-white bg-white-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-20"
                >
                  <TrashIcon
                    className="h-4 w-4 text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="space-y-2">
            {getPlatformsForDisplay(generation).map(([platform, content]) => (
              <div
                key={platform}
                className="bg-white p-3 rounded border border-gray-200"
              >
                <div className="flex justify-between items-center mb-1">
                  <h4 className="font-medium text-sm text-gray-700">
                    {platform === 'youtube_title'
                      ? 'YouTube Titles'
                      : platform === 'youtube_description'
                      ? 'YouTube Description'
                      : platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </h4>
                  <span className="text-xs text-gray-500">
                    {content.length} characters
                  </span>
                </div>
                {renderContent(content)}
              </div>
            ))}
          </div>
        </div>
      );
    },
    [onDeleteGeneration, updateLikeStatus]
  );

  // Helper function to create a description of what will be generated
  const getSelectedContentDescription = () => {
    const parts = [];

    if (inputType === 'youtube') {
      if (includeYoutubeTitle || includeYoutubeDescription) {
        const ytParts = [];
        if (includeYoutubeTitle) ytParts.push('Titles');
        if (includeYoutubeDescription) ytParts.push('Description');
        parts.push(`YouTube ${ytParts.join(' & ')}`);
      }

      if (selectedPlatforms.length > 0) {
        const platformLabels = [];
        selectedPlatforms.forEach(value => {
          const platform = platforms.find(p => p.value === value);
          if (platform) {
            platformLabels.push(platform.label.split(' ')[0]);
          }
        });

        if (platformLabels.length > 0) {
          parts.push(`${platformLabels.join(', ')} Content`);
        }
      }
    } else {
      parts.push('Content');
    }

    return parts.join(' & ') || 'Content';
  };

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="flex">
        <div className="w-1/3 pr-4">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                {id
                  ? 'Edit Social Media Content'
                  : 'Social Media Content Generator'}
              </h3>
              <form
                className="space-y-6"
                onSubmit={e => {
                  e.preventDefault();
                  if (isFormValid()) {
                    onSubmit();
                  }
                }}
              >
                <FormContainer>
                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="use-brand-voice"
                      className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                      checked={useBrandVoice && !isDisabled}
                      onChange={e => {
                        if (!isDisabled) {
                          handleUseBrandVoiceChange(e.target.checked);
                        }
                      }}
                      disabled={isDisabled}
                    />
                    <label
                      htmlFor="use-brand-voice"
                      className={`ml-2 block ${
                        isDisabled ? 'text-gray-400' : 'text-gray-900'
                      }`}
                    >
                      Use Brand Voice
                    </label>
                    <Tooltip title={tooltipText}>
                      <InformationCircleIcon className="h-5 w-5 ml-2 text-gray-500" />
                    </Tooltip>
                  </div>
                  {isDisabled && (
                    <p className="mt-1 text-sm text-gray-500">
                      {isUncategorizedProject
                        ? 'Create a project folder to use the brand voice feature.'
                        : 'Add or generate a brand voice in your project settings.'}
                    </p>
                  )}
                </FormContainer>

                <div className="flex items-center justify-center space-x-4 mb-6">
                  <button
                    type="button"
                    onClick={() => setInputType('url')}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      inputType === 'url'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    Content URL
                  </button>
                  <button
                    type="button"
                    onClick={() => setInputType('content')}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      inputType === 'content'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    Content Text
                  </button>
                  <button
                    type="button"
                    onClick={() => setInputType('youtube')}
                    className={`px-4 py-2 text-sm font-medium rounded-md ${
                      inputType === 'youtube'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    YouTube URL
                  </button>
                </div>

                {inputType === 'url' ? (
                  <FormContainer>
                    <FormLabel label="Content URL" />
                    <FormInput
                      id="content-url"
                      value={contentUrl}
                      onChange={setContentUrl}
                      required
                      inputProps={{
                        placeholder: 'https://www.example.com/your-content',
                      }}
                    />
                    {contentUrl.trim() !== '' &&
                      !isValidUrl(contentUrl.trim()) && (
                        <p className="mt-2 text-sm text-red-600">
                          Please enter a valid URL (e.g.,
                          https://www.example.com)
                        </p>
                      )}
                  </FormContainer>
                ) : inputType === 'content' ? (
                  <FormContainer>
                    <div className="char-count-container mb-2">
                      <FormLabel label="Content Text" className="form-label" />
                      <div className="w-32 text-sm font-normal text-gray-500 text-right char-count">
                        {contentText.length} / 10000
                      </div>
                    </div>
                    <FormTextarea
                      value={contentText}
                      onChange={setContentText}
                      id="content-text"
                      hideCharCount
                      maxLength={10000}
                      textareaClassName={'resize-none h-auto'}
                      rows={6}
                      placeHolder="Paste or type your content here"
                    />
                  </FormContainer>
                ) : (
                  <div>
                    <FormContainer>
                      <FormLabel label="YouTube URL" />
                      <FormInput
                        id="youtube-url"
                        value={youtubeUrl}
                        onChange={setYoutubeUrl}
                        required
                        inputProps={{
                          placeholder:
                            'https://www.youtube.com/watch?v=example',
                        }}
                      />
                      {youtubeUrl.trim() !== '' &&
                        !isValidYoutubeUrl(youtubeUrl.trim()) && (
                          <p className="mt-2 text-sm text-red-600">
                            Please enter a valid YouTube URL
                          </p>
                        )}
                    </FormContainer>

                    <div className="mt-4">
                      <div className="space-y-3 ml-2 mb-4">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="include-youtube-title"
                            checked={includeYoutubeTitle}
                            onChange={e =>
                              setIncludeYoutubeTitle(e.target.checked)
                            }
                            className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                          />
                          <label
                            htmlFor="include-youtube-title"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            Generate YouTube Titles
                          </label>
                        </div>

                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="include-youtube-description"
                            checked={includeYoutubeDescription}
                            onChange={e =>
                              setIncludeYoutubeDescription(e.target.checked)
                            }
                            className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                          />
                          <label
                            htmlFor="include-youtube-description"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            Generate YouTube Description (with timestamps and
                            hashtags)
                          </label>
                        </div>
                      </div>

                      <div className="my-4 border-t border-gray-200"></div>
                    </div>
                  </div>
                )}

                <FormContainer>
                  <FormLabel label="Target Audience" optional />
                  <FormInput
                    id="target-audience"
                    value={targetAudience}
                    onChange={setTargetAudience}
                    inputProps={{
                      placeholder:
                        'e.g., young professionals, parents, tech enthusiasts',
                    }}
                  />
                </FormContainer>

                <FormContainer>
                  <FormLabel label="Tone" />
                  <Select
                    id={getId(LABELS.tone)}
                    className="w-full"
                    options={TONES}
                    value={TONES.find(item => item.value === tone)}
                    onChange={setTone}
                  />
                </FormContainer>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Social Media Content
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    {(inputType === 'youtube'
                      ? filteredPlatforms
                      : platforms
                    ).map(platform => (
                      <div key={platform.value} className="flex items-center">
                        <input
                          type="checkbox"
                          id={platform.value}
                          checked={selectedPlatforms.includes(platform.value)}
                          onChange={() => {
                            setSelectedPlatforms(prev =>
                              prev.includes(platform.value)
                                ? prev.filter(p => p !== platform.value)
                                : [...prev, platform.value]
                            );
                          }}
                          className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={platform.value}
                          className="ml-2 block text-sm text-gray-900"
                        >
                          {platform.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {inputType !== 'youtube' && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="include-url"
                      checked={includeUrl}
                      onChange={e => setIncludeUrl(e.target.checked)}
                      className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="include-url"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      Include content URL in generated posts
                    </label>
                  </div>
                )}

                <FormContainer>
                  <div className="char-count-container mb-2">
                    <FormLabel
                      label="Instructions"
                      optional
                      className="form-label"
                    />
                    <div className="w-32 text-sm font-normal text-gray-500 text-right char-count">
                      {instructions.length} / 2000
                    </div>
                  </div>
                  <FormTextarea
                    value={instructions}
                    onChange={setInstructions}
                    id="instructions"
                    hideCharCount
                    maxLength={2000}
                    textareaClassName={'resize-none h-auto'}
                    rows={4}
                    placeHolder="Enter any specific instructions for content generation (e.g., tone, style, key points to emphasize)"
                  />
                </FormContainer>

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={handleSave}
                    className={`inline-flex items-center px-4 py-2 border border-purple-600 text-sm font-medium rounded-md text-purple-600 bg-purple-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-300 transition-colors duration-150 ease-in-out
                      ${
                        request.loading || generations.length === 0
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-purple-200 cursor-pointer'
                      }`}
                    disabled={request.loading || generations.length === 0}
                  >
                    Save Content
                  </button>
                  <button
                    type="button"
                    onClick={onSubmit}
                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-300 transition-colors duration-150 ease-in-out
                      ${
                        loadingType === 'generator' || !isFormValid()
                          ? 'bg-purple-300 cursor-not-allowed'
                          : 'bg-purple-600 hover:bg-purple-700 cursor-pointer'
                      }`}
                    disabled={loadingType === 'generator' || !isFormValid()}
                    title={!isFormValid() ? getValidationErrorMessage() : ''}
                  >
                    {loadingType === 'generator' && (
                      <LoadingIcon className="-ml-1 mr-3 h-5 w-5 text-white" />
                    )}
                    {loadingType === 'generator'
                      ? 'Generating...'
                      : 'Generate Content'}
                  </button>
                </div>
                {!isFormValid() && (
                  <div className="mt-2 text-sm text-red-500">
                    {getValidationErrorMessage()}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="w-2/3 pl-4">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Generated Content
              </h3>
              {generations.length > 0 ? (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="platform-filter"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Filter by Platform
                    </label>
                    <select
                      id="platform-filter"
                      value={selectedPlatformFilter}
                      onChange={e => setSelectedPlatformFilter(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                    >
                      <option value="all">All Platforms</option>
                      <option value="facebook">Facebook</option>
                      <option value="twitter">Twitter</option>
                      <option value="instagram">Instagram</option>
                      <option value="youtube">YouTube</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="email">Email</option>
                      <option value="pinterest">Pinterest</option>
                    </select>
                  </div>
                  <div className="space-y-6 max-h-screen overflow-y-auto">
                    {filteredGenerations.map((generation, index) =>
                      renderGenerationItem(generation, index)
                    )}
                  </div>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center h-64">
                  <div className="nova-robot-container">
                    <img
                      src={NovaRobot}
                      alt="nova-robot"
                      className="w-24 h-24 mb-4 nova-robot"
                    />
                  </div>
                  {loadingType === 'generator' ? (
                    <p className="text-gray-500 text-center loading-text">
                      Generating social media content...
                      <br />
                      This may take a moment.
                    </p>
                  ) : (
                    <p className="text-gray-500 text-center">
                      Enter your content details and click "Generate Content"
                      <br />
                      to create your first social media post.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </NewWrapper>
  );
};

export default SocialMediaContentGenerator;
