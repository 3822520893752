import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import 'bootstrap';
import { useAlert } from 'react-alert';
import useSentData from '../../services/useSentData';
import { isolateError } from '../../utils/api';
import './LibraryImageModal.scss';
import LibraryImageItem from './LibraryImageItem';
import backButton from '../../styles/images/back_button.svg';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { cleanFileName } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  novaOnBoardingCheckLists,
  novaOnBoardingSteps,
} from '../../store/reducers/nova_onboarding.reducer';
import { setNovaOnBoardingCheckList } from '../../store/actions/nova_onboarding.action';

export default function LibraryImageModal({
  opened,
  close,
  phrase,
  updateState,
  state,
  type,
}) {
  const types = {
    HeroImage: {
      multiple: false,
      text: 'featured image',
    },
    Image: {
      multiple: true,
      text: 'images',
    },
  };
  const multiple = types[type];
  const [filter, setFilter] = useState({
    page: 1,
    category: 2, // 2: Suggestions, 1: My Images, 0: AI Images
    query: '',
    per_page: 20,
    next: false,
  });
  const request = useSentData();
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(state);
  const alert = useAlert();
  const stepNovaOnBoardingGuide = useSelector(
    state => state.novaOnBoardingGuide.step
  );
  const dispatch = useDispatch();

  function loadMyImages(callback) {
    const isAIGenerated = filter.category === 0;
    request
      .send('/api/files', null, 'GET', {
        ...filter,
        is_generated: isAIGenerated,
        is_document: false,
      })
      .then(data => {
        if (!data.isAxiosError) {
          if (data) {
            filter.next = !!data.next;
            setFilter(filter);

            let result = [];
            data.results.forEach(image => {
              result.push({
                id: image.id,
                url: image.file,
                thumbnail: image.preview,
                title: cleanFileName(image.original_name),
                alt_text: cleanFileName(image.original_name),
                description: cleanFileName(image.original_name),
                owner: image.owner,
                is_generated: image.is_generated,
              });
            });
            if (filter.page === 1) {
              setImages(result);
            } else {
              setImages([...images, ...result]);
            }

            if (callback) {
              callback(result);
            }
          }
        } else {
          alert.error(isolateError(data));
        }
      });
  }

  function loadSuggestions(callback) {
    request
      .send(
        'https://api.unsplash.com/search/photos',
        null,
        'get',
        {
          query: filter.query || (phrase ?? 'marketing'),
          orientation: 'landscape',
          page: filter.page,
          per_page: filter.per_page,
        },
        {
          Authorization:
            'Client-ID qfjYEkmTI1xO8p7LuVgmi3euVHvAfY-svZfFepM9xc4',
        }
      )
      .then(data => {
        if (!data.isAxiosError) {
          if (data) {
            filter.next = data.total_pages >= filter.page;
            setFilter(filter);

            let result = [];
            data.results.forEach(image => {
              result.push({
                id: image.id,
                url: image.urls.regular,
                thumbnail: image.urls.thumb,
                title: image.alt_description,
                alt_text: image.alt_description,
                description: image.description,
                owner: image.user.name,
                owner_username: image.user.username,
                download_location: image.links.download_location,
              });
            });

            if (filter.page === 1) {
              setImages(result);
            } else {
              setImages([...images, ...result]);
            }

            if (callback) {
              callback(result);
            }
          }
        } else {
          alert.error(isolateError(data));
        }
      });
  }

  function loadImages(callback) {
    if (filter.category === 2) {
      loadSuggestions(callback);
    } else {
      loadMyImages(callback);
    }
  }

  useEffect(loadImages, []);

  function uploadFiles(files) {
    setCategory(1, images => {
      files.forEach(file => {
        file.id = uuid();
        images.unshift({
          id: file.id,
          url: URL.createObjectURL(file),
          thumbnail: URL.createObjectURL(file),
          title: file.name,
          alt_text: file.name,
          description: file.name,
          loading: true,
        });
      });
      setImages([...images]);

      files.forEach(file => {
        const data = new FormData();
        data.append('file', file);
        request.send('/api/files', data, 'POST').then(res => {
          const index = images.findIndex(i => i.id === file.id);
          images[index] = {
            id: res.id,
            url: res.file,
            thumbnail: res.preview,
            title: cleanFileName(res.original_name),
            alt_text: cleanFileName(res.original_name),
            description: cleanFileName(res.original_name),
          };
          setImages([...images]);
        });
      });
    });
  }

  function setCategory(category, callback) {
    if (category === filter.category) {
      if (callback) {
        callback(images);
      }
      return;
    }
    filter.category = category;
    filter.page = 1;
    setFilter(filter);
    loadImages(callback);
  }

  function setPage() {
    filter.page += 1;
    setFilter(filter);
    loadImages();
  }

  function setQuery(e) {
    filter.page = 1;
    filter.query = e.target.querySelector('input').value;
    setFilter(filter);
    e.preventDefault();
    loadImages();
  }

  function handleSelectImage(image) {
    let selected_images = [...selected];
    const index = selected.findIndex(file => file.id === image.id);
    if (index > -1) {
      selected_images.splice(index, 1);
    } else {
      if (multiple.multiple) {
        selected_images.push(image);
      } else {
        selected_images = [image];
      }
    }
    setSelected(selected_images);
  }

  function updateSelected() {
    if (multiple.multiple) {
      updateState(selected);
    } else {
      updateState(selected[0]);
    }
    if (stepNovaOnBoardingGuide === novaOnBoardingSteps.COMPLETE_CHECK_LIST) {
      dispatch(
        setNovaOnBoardingCheckList(novaOnBoardingCheckLists.INCLUDE_AN_IMAGE)
      );
    }
    triggerDownloadImage(selected);
  }

  function triggerDownloadImage(selected_images) {
    Promise.all(
      selected_images.map(item => {
        request.send(item.download_location, null, 'get', null, {
          Authorization:
            'Client-ID qfjYEkmTI1xO8p7LuVgmi3euVHvAfY-svZfFepM9xc4',
        });
      })
    );
  }

  const renderImages = images.map((image, i) => {
    let isSelected = false;
    if (selected.find(file => file.id === image.id)) {
      isSelected = true;
    }
    return (
      <LibraryImageItem
        key={image.id}
        image={image}
        isSelected={isSelected}
        handleSelectImage={handleSelectImage}
      />
    );
  });

  return (
    <Modal className="library-image-modal" isOpen={opened} ariaHideApp={false}>
      <div className="header-wrapper">
        <div className="md:flex">
          <div className="col-sm-3 col-md-2 w-full md:w-1/5">
            <button
              className="clear-button w-8 h-8 md:w-12 md:h-12"
              onClick={close}
            >
              <img src={backButton} alt="" />
            </button>
          </div>
          <div className="col-sm-9 col-md-10 w-full md:w-8/10 text-xs">
            <h1 className="">
              Select {multiple.text} from{' '}
              {filter.category === 2
                ? 'Unsplash'
                : filter.category === 1
                ? 'Uploaded Images'
                : 'AI Images'}
            </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="md:flex mt-4 md:mt-0">
          <div className="side-menu w-full md:w-1/5 md:pr-6">
            <ul>
              <li
                className={classNames('', { active: filter.category === 2 })}
                onClick={() => setCategory(2)}
              >
                Suggestions
              </li>
              <li
                className={classNames('', { active: filter.category === 1 })}
                onClick={() => setCategory(1)}
              >
                Uploaded Images
              </li>
              <li
                className={classNames('', { active: filter.category === 0 })}
                onClick={() => setCategory(0)}
              >
                AI Images
              </li>
            </ul>
            <Dropzone multiple={true} accept="image/*" onDrop={uploadFiles}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className="btn btn-block btn-green file-select text-center cursor-pointer"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  Upload files
                </div>
              )}
            </Dropzone>
            <button
              className="btn btn-block btn-purple w-full"
              onClick={updateSelected}
              disabled={!selected.length}
            >
              Done
            </button>
            <span className="my-2 md:my-1">
              {selected.length} image(s) selected
            </span>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-10 w-full md:w-8/10">
            <form onSubmit={setQuery}>
              <div className="input-group input-group-lg flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search images"
                />
                <div className="input-group-append">
                  <button className="btn btn-purple px-6 -ml-1">Search</button>
                </div>
              </div>
            </form>
            <div className="images-wrapper">
              <div className="images">{renderImages}</div>
              <div className="text-center load-more">
                {filter.next && (
                  <button className="btn btn-green" onClick={setPage}>
                    Load more images
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
