import axios from 'axios';
import { getAuthToken } from '../utils/auth';

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}/api`;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add auth token to requests
api.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

// Add error handling
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized error
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const dataForSeoService = {
  // Get comprehensive data for a keyword
  getKeywordMetrics: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en'
  ) => {
    const response = await api.post('/keyword-metrics/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
    });
    return response.data;
  },

  // Get related keywords
  getRelatedKeywords: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en',
    limit = 10
  ) => {
    const response = await api.post('/related-keywords/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },

  // Get keyword clusters
  getKeywordClusters: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en'
  ) => {
    const response = await api.post('/keyword-clusters/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
    });
    return response.data;
  },

  // Get top questions for a keyword
  getTopQuestions: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en',
    limit = 10
  ) => {
    const response = await api.post('/top-questions/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },

  // Get top ranking posts for a keyword
  getTopRankingPosts: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en',
    depth = 10
  ) => {
    const response = await api.post('/top-ranking-posts/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
      depth,
    });
    return response.data;
  },

  // Get ranked keywords for a domain
  getRankedKeywords: async (
    domain,
    locationCode = '2840',
    languageCode = 'en',
    limit = 100
  ) => {
    const response = await api.post('/ranked-keywords/', {
      domain,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },

  // Get relevant pages for a domain
  getRelevantPages: async (
    domain,
    locationCode = '2840',
    languageCode = 'en',
    limit = 100
  ) => {
    const response = await api.post('/relevant-pages/', {
      domain,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },

  // Get keywords for a site
  getKeywordsForSite: async (
    domain,
    locationCode = '2840',
    languageCode = 'en',
    limit = 100,
    offset = 0
  ) => {
    const response = await api.post('/keywords-for-site/', {
      domain,
      location_code: locationCode,
      language_code: languageCode,
      limit,
      offset,
    });
    return response.data;
  },

  // Get keyword ideas
  getKeywordIdeas: async (
    keywords,
    locationCode = '2840',
    languageCode = 'en',
    limit = 100
  ) => {
    const response = await api.post('/keyword-ideas/', {
      keywords,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },

  // Get keyword suggestions
  getKeywordSuggestions: async (
    keyword,
    locationCode = '2840',
    languageCode = 'en',
    limit = 100
  ) => {
    const response = await api.post('/keyword-suggestions/', {
      keyword,
      location_code: locationCode,
      language_code: languageCode,
      limit,
    });
    return response.data;
  },
};
