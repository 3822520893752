import React, { useEffect, useRef, useState } from 'react';
import '../../pages/Chat.scss';
import {
  PaperAirplaneIcon,
  MicrophoneIcon,
  LightningBoltIcon,
} from '@heroicons/react/solid';
import { InformationCircleIcon, PaperClipIcon } from '@heroicons/react/outline';
import {
  useSaveChatMessage,
  useSaveOrUpdateConversation,
  useSendMessage,
} from '../../utils/chatApi';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { track } from '../../services/track';
import {
  setActiveChatId,
  setMessages as setMessageList,
  setChatMessage,
  setLoading,
  loadConversations,
  setLookInTheEditor,
  setConnectToTheWeb,
} from '../../store/actions/chat.actions';
import Tooltip from '@material-ui/core/Tooltip';
import useSentData from '../../services/useSentData';
import { useAlert } from 'react-alert';
import { isolateError } from '../../utils/api';

const ChatInput = ({ chatId, editorRef, isInBuilder = false }) => {
  const isPanelMode = useSelector(state => state.chat.isPanelMode);
  const isLoading = useSelector(state => state.chat.loading);
  const lookInTheEditor = useSelector(state => state.chat.lookInTheEditor);
  const connectToTheWeb = useSelector(state => state.chat.connectToTheWeb);
  const messages = useSelector(state => state.chat.messages);
  const chatMessage = useSelector(state => state.chat.chatMessage);

  // Get user access permissions for AI Agent (previously Smart Web Navigator)
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );
  const hasTrialSubscription = useSelector(
    state => state.auth?.user?.has_trial_subscription
  );

  // Check if user has permission to use AI Agent
  const hasAIAgentAccess =
    webAccessEnabled || smartWebNavigatorAccess || hasTrialSubscription;

  const textareaRef = useRef(null);

  const { sendMessage } = useSendMessage();

  const { saveChatMessage } = useSaveChatMessage();

  const { saveOrUpdateConversation } = useSaveOrUpdateConversation();

  const history = useHistory();

  const [inputText, setInputText] = useState('');
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const [isImprovingPrompt, setIsImprovingPrompt] = useState(false);

  const dispatch = useDispatch();

  const setMessages = messages => {
    dispatch(setMessageList(messages));
  };

  const setIsLoading = loading => {
    dispatch(setLoading(loading));
  };

  const request = useSentData();
  const alert = useAlert();

  useEffect(() => {
    setInputText(chatMessage);
  }, [chatMessage]);

  const handleInputChange = e => {
    setInputText(e.target.value);
  };

  // Force reset textarea height in builder mode
  useEffect(() => {
    if (isInBuilder && textareaRef.current) {
      // Force a fixed height for the textarea in builder mode
      textareaRef.current.style.height = '38px';
      textareaRef.current.style.minHeight = '38px';
      textareaRef.current.style.padding = '8px 40px 8px 16px';
      textareaRef.current.style.backgroundColor = '#f5f7f9';
      textareaRef.current.style.border = '1px solid #e5e7eb';
      textareaRef.current.style.borderRadius = '8px';
      textareaRef.current.style.fontSize = '0.9rem';
      textareaRef.current.style.boxShadow = 'none';
      textareaRef.current.style.width = '100%';
    }
  }, [isInBuilder]);

  // Adjust the adjustment to handle builder mode
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;

      // If in builder mode, maintain a fixed height
      if (isInBuilder) {
        textarea.style.height = '38px';
        return;
      }

      // Normal adjustment for non-builder mode
      textarea.style.height = 'auto';
      const newHeight = Math.min(Math.max(textarea.scrollHeight, 44), 200);
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    // Always adjust height when input text changes
    adjustTextareaHeight();
  }, [inputText]);

  useEffect(() => {
    // Initial adjustment when component mounts
    adjustTextareaHeight();

    // Add a small delay to ensure content is fully rendered
    const resizeObserver = new ResizeObserver(() => {
      adjustTextareaHeight();
    });

    if (textareaRef.current) {
      resizeObserver.observe(textareaRef.current);
    }

    return () => {
      if (textareaRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  const handleSendMessage = async () => {
    if (isLoading) {
      return;
    }
    const isNewChat = !chatId;
    if (!chatId) {
      chatId = uuid();
      if (!isPanelMode) {
        history.push(`/chat/${chatId}`);
      }
      dispatch(setActiveChatId(chatId));
    }
    if (inputText.trim()) {
      const userMessage = {
        role: 'user',
        content: inputText.trim(),
        created_at: new Date().toLocaleString(),
      };
      track('Chat message', { Message: inputText.trim() });
      dispatch(setChatMessage(''));
      setIsLoading(true);
      const userMessageId = await saveChatMessage(chatId, userMessage);
      if (userMessageId) {
        let updatedMessages = [
          ...messages,
          { id: userMessageId, ...userMessage },
        ];
        setInputText('');
        setMessages(updatedMessages);

        try {
          const aiResponse = await sendMessage(
            [...updatedMessages.map(({ id, created_at, ...rest }) => rest)],
            {
              connectToTheWeb,
              lookInTheEditor,
              lookback: lookInTheEditor
                ? editorRef?.current?.editor.getContent({ format: 'text' })
                : undefined,
            }
          );

          if (aiResponse) {
            const aiMessage = {
              role: 'assistant',
              content: aiResponse,
              created_at: new Date().toLocaleString(),
            };
            const aiMessageId = await saveChatMessage(chatId, aiMessage);
            if (aiMessageId) {
              updatedMessages = [
                ...updatedMessages,
                { id: aiMessageId, ...aiMessage },
              ];
              await saveOrUpdateConversation(chatId, inputText.trim());
            }
            setIsLoading(false);
            setMessages(updatedMessages);
            if (isNewChat) {
              dispatch(loadConversations());
            }
          }
        } catch (error) {
          console.error('Error sending message to API:', error);
          setIsLoading(false);
        }
      }
    }
  };

  const toggleConnectToTheWeb = () => {
    if (!hasAIAgentAccess) {
      // If user doesn't have access, show upgrade message
      alert('Please upgrade your plan to use the AI Agent feature.');
      return;
    }
    dispatch(setConnectToTheWeb(!connectToTheWeb));
  };

  const toggleVoiceRecording = () => {
    if (
      !('webkitSpeechRecognition' in window) &&
      !('SpeechRecognition' in window)
    ) {
      alert('Voice input is not supported in this browser');
      return;
    }

    if (isVoiceRecording) {
      setIsVoiceRecording(false);
      // Stop voice recording logic would go here
      return;
    }

    setIsVoiceRecording(true);

    // Browser speech recognition API
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = event => {
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        }
      }

      if (finalTranscript) {
        setInputText(prevText => prevText + ' ' + finalTranscript);
      }
    };

    recognition.onend = () => {
      setIsVoiceRecording(false);
    };

    recognition.onerror = event => {
      console.error('Speech recognition error', event.error);
      setIsVoiceRecording(false);
    };

    recognition.start();
  };

  const handleImprovePrompt = async () => {
    if (!inputText.trim() || isImprovingPrompt) {
      alert.info('Please enter a prompt to improve');
      return;
    }

    setIsImprovingPrompt(true);
    track('Improve Prompt', { Action: 'Started' });

    try {
      const response = await request.send('/api/improve-prompt', {
        prompt: inputText.trim(),
      });

      if (!response.isAxiosError) {
        if (response.success && response.content?.text) {
          setInputText(response.content.text);

          // Force height adjustment after a short delay to ensure the DOM has updated
          setTimeout(() => {
            adjustTextareaHeight();
          }, 50);

          track('Improve Prompt', { Success: true });
        } else {
          alert.error(
            response.message || 'Failed to improve prompt. Please try again.'
          );
          track('Improve Prompt', { Success: false, Error: response.message });
        }
      } else {
        alert.error(isolateError(response));
        track('Improve Prompt', {
          Success: false,
          Error: isolateError(response),
        });
      }
    } catch (error) {
      console.error('Error improving prompt:', error);
      alert.error(
        'An error occurred while improving the prompt. Please try again.'
      );
      track('Improve Prompt', { Success: false, Error: error.message });
    } finally {
      setIsImprovingPrompt(false);
    }
  };

  const handleAttachment = () => {
    // This functionality will be implemented later
    alert.info('Attachment feature coming soon!');
  };

  // Simplified feature buttons for builder mode
  const renderFeatureButtons = () => {
    if (isInBuilder) {
      return (
        <div className="chat-feature-buttons flex items-center justify-between mt-1 in-builder">
          <div className="feature-buttons-left flex items-center space-x-1">
            <Tooltip title="Voice input" arrow placement="top">
              <button onClick={toggleVoiceRecording} className="feature-button">
                <MicrophoneIcon className="h-3 w-3" />
                <span>Voice</span>
              </button>
            </Tooltip>

            <Tooltip title="Improve prompt" arrow placement="top">
              <button onClick={handleImprovePrompt} className="feature-button">
                <LightningBoltIcon className="h-3 w-3" />
                <span>Improve</span>
              </button>
            </Tooltip>
          </div>

          <div className="feature-buttons-right">
            <div className="ai-agent-toggle flex items-center space-x-1">
              <div className="agent-label">
                <span>AI Agent</span>
              </div>
              <label
                className={`switch ${!hasAIAgentAccess ? 'opacity-60' : ''}`}
              >
                <input
                  type="checkbox"
                  checked={connectToTheWeb}
                  onChange={toggleConnectToTheWeb}
                  disabled={!hasAIAgentAccess}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
      );
    }

    // Default rendering for non-builder mode
    return (
      <div className="chat-feature-buttons flex items-center justify-between mt-1">
        <div className="feature-buttons-left flex items-center space-x-1.5">
          <Tooltip title="Voice input" arrow placement="top">
            <button
              onClick={toggleVoiceRecording}
              className={`feature-button ${
                isVoiceRecording ? 'recording' : ''
              }`}
            >
              <MicrophoneIcon className="h-4 w-4" />
              <span>Voice</span>
            </button>
          </Tooltip>

          <Tooltip title="Improve your prompt" arrow placement="top">
            <button
              onClick={handleImprovePrompt}
              className={`feature-button ${isImprovingPrompt ? 'loading' : ''}`}
              disabled={isImprovingPrompt || isLoading}
            >
              {isImprovingPrompt ? (
                <svg
                  className="animate-spin h-4 w-4 text-purple-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <LightningBoltIcon className="h-4 w-4" />
              )}
              <span>{isImprovingPrompt ? 'Improving...' : 'Improve'}</span>
            </button>
          </Tooltip>

          <Tooltip title="Attach files" arrow placement="top">
            <button onClick={handleAttachment} className="feature-button">
              <PaperClipIcon className="h-4 w-4" />
              <span>Attach</span>
            </button>
          </Tooltip>
        </div>

        <div className="feature-buttons-right">
          <div className="ai-agent-toggle flex items-center space-x-1.5">
            <Tooltip
              title={
                hasAIAgentAccess
                  ? 'Connect to Web, Boost SEO, Extract & Analyze'
                  : 'Upgrade your plan to enable AI Agent functionality'
              }
              arrow
              placement="top"
            >
              <div className="agent-label flex items-center">
                <span className="mr-1">AI Agent</span>
                <InformationCircleIcon className="h-4 w-4 text-gray-400" />
              </div>
            </Tooltip>
            <label
              className={`switch ${!hasAIAgentAccess ? 'opacity-60' : ''}`}
            >
              <input
                type="checkbox"
                checked={connectToTheWeb}
                onChange={toggleConnectToTheWeb}
                disabled={!hasAIAgentAccess}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`chat-input-container ${isInBuilder ? 'in-builder' : ''}`}>
      <div
        className={`chat-input-wrapper flex flex-col w-full relative ${
          isInBuilder ? 'builder-input-wrapper' : ''
        }`}
      >
        <div
          className={`textarea-container relative w-full ${
            isInBuilder ? 'builder-textarea' : ''
          }`}
        >
          <textarea
            ref={textareaRef}
            className={`chat-input w-full ${isLoading ? 'opacity-50' : ''} ${
              isInBuilder ? 'in-builder' : ''
            }`}
            placeholder="Type a message..."
            value={inputText}
            onChange={e => {
              handleInputChange(e);
              // Height will be adjusted in the useEffect
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            disabled={isLoading}
            rows="1"
          />
          <div
            className={`chat-input-actions ${isInBuilder ? 'in-builder' : ''}`}
          >
            <Tooltip title="Send message" placement="top" arrow>
              <button
                className={`chat-send-button ${
                  isInBuilder ? 'in-builder' : ''
                }`}
                onClick={handleSendMessage}
                disabled={isLoading || !inputText.trim()}
              >
                <PaperAirplaneIcon className="h-5 w-5 rotate-90" />
              </button>
            </Tooltip>
          </div>
        </div>

        {renderFeatureButtons()}
      </div>
    </div>
  );
};

export default ChatInput;
