import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';

import UnAuthRoute from './routes/UnAuthRoute';
import 'tailwindcss/tailwind.css';
import './styles/fonts.css';
import './styles/common.scss';
import './styles/main.scss';
import NoMatch from './pages/NoMatch';
import Login from './pages/Login';
import ConnectWordPress from './pages/ConnectWordPress';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Activate from './pages/Activate';
import PrivateRoute, {
  Authentication as AuthenticationRoute,
} from './routes/PrivateRoute';
import Dashboard from './pages/Dashboard';
import PreDashboard from './pages/PreDashboard';
import Plan from './pages/Account/Plan';
import AppSumoPlan from './pages/Account/AppSumoPlan';
import BrameworkLTDPlan from './pages/Account/BrameworkLTDPlan';
import Account from './pages/Account';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Team from './pages/Team';
import Integrations from './pages/Integrations';
import PublishHistory from './pages/PublishHistory';
import ConfirmChangeEmail from './pages/ConfirmChangeEmail';
import OnBoarding from './pages/OnBoarding';
import PostTemplate from './pages/PostTemplate';
import PostCreate from './pages/PostCreate';
import NewPost from './pages/NewPost';
import Reactivate from './pages/Reactivate';
import CheckoutComplete from './pages/CheckoutComplete';
import Invoices from './pages/Invoices';
import Performance from './pages/Performance';
import Keywords from './pages/Keywords';
import Outlines from './pages/Outlines';
import Titles from './pages/Titles';
import Drafts from './pages/Drafts';
import Projects from './pages/Projects';
import { BloggerOnBoarding } from './pages/BloggerOnBoarding';
import RejoinTeam from './pages/RejoinTeam';
import TitleGenerator from './pages/TitleGenerator';
import OutlineGenerator from './pages/OutlineGenerator';
import MainPostBuilder from './pages/MainPostBuilder';
import PostBuilderPreview from './pages/PostBuilderPreview';
import OneSession from './pages/OneSession/OneSession';
import { isMobile } from './utils/common';
import useSentData from './services/useSentData';
import { getSemrushStatus, semrushStatuses } from './utils/semrush';
import { updateUser } from './store/actions/auth.actions';
import GenerateFirstDraft from './pages/GenerateFirstDraft/GenerateFirstDraft';
import GenerateFirstDraftPro from './pages/GenerateFirstDraft/GenerateFirstDraftPro';
import BulkFirstDraftPro from './pages/GenerateFirstDraft/BulkFirstDraftPro';
import BlogPostImprover from './pages/GenerateFirstDraft/BlogPostImprover';
import Chat from './pages/Chat';
import Rewrites from './pages/Rewrites';
import RewriteGenerator from './pages/RewriteGenerator';
import Summaries from './pages/Summaries';
import SummaryGenerator from './pages/SummaryGenerator';
// import GoogleAnalytics from './pages/AnalyticDashboard/GoogleAnalytics';
import SocialMediaContentGenerator from './components/SocialMediaContentGenerator';
import SocialContent from './pages/SocialContent';
import BlogToVideo from './pages/VideoCreator/BlogToVideo';
import VideoDashboard from './pages/VideoCreator/VideoDashboard';
import KeywordResearchPage from './pages/KeywordResearchPage';

function App() {
  const [emailMsgShown, setEmailMsgShown] = useState(false);
  const [semrushStatusUpdated, setSemrushStatusUpdated] = useState(false);
  const user = useSelector(state => state.auth.user);

  const alert = useAlert();
  const request = useSentData();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!user && !emailMsgShown) {
      const isUserEmailConfirmed = user.is_confirmed;
      if (!isUserEmailConfirmed) {
        alert.info(
          'We have sent you confirmation email, please confirm your email.'
        );
        setEmailMsgShown(true);
      }
    }

    if (!!user && user.is_owner && !semrushStatusUpdated) {
      getSemrushStatus(request)
        .then(status => {
          dispatch(
            updateUser({
              isSemrushConnected: status === semrushStatuses.connected,
            })
          );
          setSemrushStatusUpdated(true);
        })
        .catch(err => console.error(err));
    }
  }, [user, emailMsgShown, semrushStatusUpdated]);

  useEffect(() => {
    if (isMobile.any()) {
      alert.error(
        'Please access Bramework using your desktop — mobile access is currently limited.'
      );
    }
  }, [isMobile.any()]);

  return (
    <Switch>
      <UnAuthRoute exact path="/" component={Login} />
      <UnAuthRoute exact path="/register" component={Register} />
      <Route exact path="/add-member" component={RejoinTeam} />
      <Route exact path="/activate/:token" component={Activate} />
      <UnAuthRoute exact path="/reactivate/:token" component={Reactivate} />
      <UnAuthRoute exact path="/forgot-password" component={ForgotPassword} />
      <UnAuthRoute
        exact
        path="/reset-password/:token"
        component={ResetPassword}
      />
      <UnAuthRoute
        exact
        path="/connect-wordpress"
        component={ConnectWordPress}
      />
      <AuthenticationRoute exact path="/dashboard" component={PreDashboard} />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/complete-checkout"
        component={CheckoutComplete}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/dashboard"
        component={Dashboard}
      />
      <PrivateRoute accessBy={['owner']} exact path="/plans" component={Plan} />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/appsumo-plan"
        component={AppSumoPlan}
      />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/bramework-ltd-plan"
        component={BrameworkLTDPlan}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/drafts"
        component={Drafts}
      />
      <PrivateRoute exact path="/projects" component={Projects} />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/blogger-onboarding"
        component={BloggerOnBoarding}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/chat"
        component={Chat}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/chat/:chatId"
        component={Chat}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/keywords"
        component={Keywords}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/keyword-research-new"
        component={KeywordResearchPage}
      />
      <PrivateRoute
        exact
        path="/project/:project_id/keyword-research-new/:id"
        component={KeywordResearchPage}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/titles"
        component={Titles}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/outlines"
        component={Outlines}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/rewrites"
        component={Rewrites}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/rewrite-generator"
        component={RewriteGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/rewrite-generator/:id"
        component={RewriteGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/summaries"
        component={Summaries}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/summary-generator"
        component={SummaryGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/summary-generator/:id"
        component={SummaryGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/title-generator"
        component={TitleGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/title-generator/:id"
        component={TitleGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/outline-generator"
        component={OutlineGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/outline-generator/:id"
        component={OutlineGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/profile"
        component={Profile}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/social-content"
        component={SocialContent}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/social-media-generator"
        component={SocialMediaContentGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/project/:project_id/social-media-generator/:id"
        component={SocialMediaContentGenerator}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/account"
        component={Account}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/settings"
        component={Settings}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/team"
        component={Team}
      />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/integrations"
        component={Integrations}
      />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/publish-history"
        component={PublishHistory}
      />
      <PrivateRoute exact path="/onboarding" component={OnBoarding} />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/post-template"
        component={PostTemplate}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/post-create"
        component={PostCreate}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/keyword-analyzer"
        component={NewPost}
      />
      <PrivateRoute
        exact
        path="/project/:project_id/keyword-analyzer/:id"
        component={NewPost}
      />
      <PrivateRoute
        accessBy={['owner']}
        exact
        path="/invoices"
        component={Invoices}
      />
      <PrivateRoute
        accessBy={['owner', 'manager']}
        exact
        path="/project/:project_id/performance"
        component={Performance}
      />
      <PrivateRoute
        accessBy={['owner', 'manager']}
        exact
        path="/project/:project_id/performance/:id"
        component={Performance}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/post-builder"
        component={MainPostBuilder}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/post-builder/:id"
        component={MainPostBuilder}
      />
      <PrivateRoute
        exact
        path="/project/:project_id/post-preview"
        component={PostBuilderPreview}
      />
      <PrivateRoute
        exact
        path="/project/:project_id/post-preview/:id"
        component={PostBuilderPreview}
      />
      <Route
        exact
        path="/confirm-change-email/:token"
        component={ConfirmChangeEmail}
      />
      <Route exact path="/one-session" component={OneSession} />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/generate-first-draft"
        component={GenerateFirstDraft}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/generate-first-draft-pro"
        component={GenerateFirstDraftPro}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/bulk-first-draft-pro"
        component={BulkFirstDraftPro}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/project/:project_id/blog-post-improver"
        component={BlogPostImprover}
      />
      {/* <PrivateRoute
        accessBy={['owner', 'manager', 'editor', 'viewer']}
        exact
        path="/google-analytics"
        component={GoogleAnalytics}
      /> */}
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/videos"
        component={VideoDashboard}
      />
      <PrivateRoute
        accessBy={['owner', 'manager', 'editor']}
        exact
        path="/videos/create"
        component={BlogToVideo}
      />
      <Route path="" component={NoMatch} />
    </Switch>
  );
}

export default App;
