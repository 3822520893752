// DataForSEO location and language data mapping
export const DATAFORSEO_LOCATIONS = [
  {
    location_code: 2840,
    location_name: 'United States',
    country_iso_code: 'US',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2826,
    location_name: 'United Kingdom',
    country_iso_code: 'GB',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2124,
    location_name: 'Canada',
    country_iso_code: 'CA',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2036,
    location_name: 'Australia',
    country_iso_code: 'AU',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2276,
    location_name: 'Germany',
    country_iso_code: 'DE',
    language_name: 'German',
    language_code: 'de',
  },
  {
    location_code: 2250,
    location_name: 'France',
    country_iso_code: 'FR',
    language_name: 'French',
    language_code: 'fr',
  },
  {
    location_code: 2724,
    location_name: 'Spain',
    country_iso_code: 'ES',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2380,
    location_name: 'Italy',
    country_iso_code: 'IT',
    language_name: 'Italian',
    language_code: 'it',
  },
  {
    location_code: 2528,
    location_name: 'Netherlands',
    country_iso_code: 'NL',
    language_name: 'Dutch',
    language_code: 'nl',
  },
  {
    location_code: 2356,
    location_name: 'India',
    country_iso_code: 'IN',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2392,
    location_name: 'Japan',
    country_iso_code: 'JP',
    language_name: 'Japanese',
    language_code: 'ja',
  },
  {
    location_code: 2076,
    location_name: 'Brazil',
    country_iso_code: 'BR',
    language_name: 'Portuguese',
    language_code: 'pt',
  },
  {
    location_code: 2643,
    location_name: 'Russia',
    country_iso_code: 'RU',
    language_name: 'Russian',
    language_code: 'ru',
  },
  {
    location_code: 2158,
    location_name: 'Taiwan',
    country_iso_code: 'TW',
    language_name: 'Chinese (Traditional)',
    language_code: 'zh-TW',
  },
  {
    location_code: 2344,
    location_name: 'Hong Kong',
    country_iso_code: 'HK',
    language_name: 'Chinese (Traditional)',
    language_code: 'zh-TW',
  },
  {
    location_code: 2702,
    location_name: 'Singapore',
    country_iso_code: 'SG',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2360,
    location_name: 'Indonesia',
    country_iso_code: 'ID',
    language_name: 'Indonesian',
    language_code: 'id',
  },
  {
    location_code: 2764,
    location_name: 'Thailand',
    country_iso_code: 'TH',
    language_name: 'Thai',
    language_code: 'th',
  },
  {
    location_code: 2458,
    location_name: 'Malaysia',
    country_iso_code: 'MY',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2608,
    location_name: 'Philippines',
    country_iso_code: 'PH',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2704,
    location_name: 'Vietnam',
    country_iso_code: 'VN',
    language_name: 'Vietnamese',
    language_code: 'vi',
  },
  {
    location_code: 2410,
    location_name: 'South Korea',
    country_iso_code: 'KR',
    language_name: 'Korean',
    language_code: 'ko',
  },
  {
    location_code: 2792,
    location_name: 'Turkiye',
    country_iso_code: 'TR',
    language_name: 'Turkish',
    language_code: 'tr',
  },
  {
    location_code: 2616,
    location_name: 'Poland',
    country_iso_code: 'PL',
    language_name: 'Polish',
    language_code: 'pl',
  },
  {
    location_code: 2752,
    location_name: 'Sweden',
    country_iso_code: 'SE',
    language_name: 'Swedish',
    language_code: 'sv',
  },
  {
    location_code: 2208,
    location_name: 'Denmark',
    country_iso_code: 'DK',
    language_name: 'Danish',
    language_code: 'da',
  },
  {
    location_code: 2246,
    location_name: 'Finland',
    country_iso_code: 'FI',
    language_name: 'Finnish',
    language_code: 'fi',
  },
  {
    location_code: 2578,
    location_name: 'Norway',
    country_iso_code: 'NO',
    language_name: 'Norwegian (Bokmål)',
    language_code: 'nb',
  },
  {
    location_code: 2203,
    location_name: 'Czechia',
    country_iso_code: 'CZ',
    language_name: 'Czech',
    language_code: 'cs',
  },
  {
    location_code: 2348,
    location_name: 'Hungary',
    country_iso_code: 'HU',
    language_name: 'Hungarian',
    language_code: 'hu',
  },
  {
    location_code: 2642,
    location_name: 'Romania',
    country_iso_code: 'RO',
    language_name: 'Romanian',
    language_code: 'ro',
  },
  {
    location_code: 2300,
    location_name: 'Greece',
    country_iso_code: 'GR',
    language_name: 'Greek',
    language_code: 'el',
  },
  {
    location_code: 2620,
    location_name: 'Portugal',
    country_iso_code: 'PT',
    language_name: 'Portuguese',
    language_code: 'pt',
  },
  {
    location_code: 2372,
    location_name: 'Ireland',
    country_iso_code: 'IE',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2554,
    location_name: 'New Zealand',
    country_iso_code: 'NZ',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2056,
    location_name: 'Belgium',
    country_iso_code: 'BE',
    language_name: 'Dutch',
    language_code: 'nl',
  },
  {
    location_code: 2756,
    location_name: 'Switzerland',
    country_iso_code: 'CH',
    language_name: 'German',
    language_code: 'de',
  },
  {
    location_code: 2040,
    location_name: 'Austria',
    country_iso_code: 'AT',
    language_name: 'German',
    language_code: 'de',
  },
  {
    location_code: 2784,
    location_name: 'United Arab Emirates',
    country_iso_code: 'AE',
    language_name: 'Arabic',
    language_code: 'ar',
  },
  {
    location_code: 2682,
    location_name: 'Saudi Arabia',
    country_iso_code: 'SA',
    language_name: 'Arabic',
    language_code: 'ar',
  },
  {
    location_code: 2818,
    location_name: 'Egypt',
    country_iso_code: 'EG',
    language_name: 'Arabic',
    language_code: 'ar',
  },
  {
    location_code: 2710,
    location_name: 'South Africa',
    country_iso_code: 'ZA',
    language_name: 'English',
    language_code: 'en',
  },
  {
    location_code: 2484,
    location_name: 'Mexico',
    country_iso_code: 'MX',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2032,
    location_name: 'Argentina',
    country_iso_code: 'AR',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2170,
    location_name: 'Colombia',
    country_iso_code: 'CO',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2152,
    location_name: 'Chile',
    country_iso_code: 'CL',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2604,
    location_name: 'Peru',
    country_iso_code: 'PE',
    language_name: 'Spanish',
    language_code: 'es',
  },
  {
    location_code: 2862,
    location_name: 'Venezuela',
    country_iso_code: 'VE',
    language_name: 'Spanish',
    language_code: 'es',
  },
];

// Convert to format expected by RegionFormSelect
export const DATAFORSEO_REGIONS = DATAFORSEO_LOCATIONS.map(location => ({
  label: location.location_name,
  value: location.location_code.toString(),
  language_code: location.language_code,
}));
