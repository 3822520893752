import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Popover } from '@headlessui/react';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';

import './NewPost.scss';
import { dataForSeoService } from '../services/dataForSeoService';
import Loading from '../components/Loading';
import RegionFormSelect from '../components/form/RegionFormSelect';
import { track } from '../services/track';
import { isolateError } from '../utils/api';
import { DATAFORSEO_REGIONS } from '../utils/dataforseoLocations';
import Tip from '../components/Tip';
import ShowlessButton from '../components/keyword-analyzer/showless-button';
import ShowmoreButton from '../components/keyword-analyzer/showmore-button';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';
import { formatNumber } from '../utils/common';
import QualityConfirmationModal from './NewPost/QualityConfirmationModal';
import useSentData from '../services/useSentData';

// Import components from NewPost
import ResultItem from './NewPost/ResultItem';
import QuestionItem from './NewPost/QuestionItem';
import KeywordItem from './NewPost/KeywordItem';
import PostItem from './NewPost/PostItem';
import Trend from './NewPost/Trend';
import NovaMessage from './NewPost/NovaMessage';

// Import complexity calculation utils
import {
  competitionComplexity,
  difficultyComplexity,
  resultsComplexity,
  volumeComplexity,
} from '../utils/keywords';

// Import icons
import novaIcon from '../styles/images/nova-onboarding.svg';
import { ExclamationIcon } from '@heroicons/react/solid';

// Add the constant at the top level to be accessible in both components
const ITEMS_TO_SHOW = 10;

function NoData() {
  return <div className="no-data">No data available at this time.</div>;
}

function NoResult(title) {
  return (
    <div className="no-result-wrapper">
      <div className="title">
        Your search: <span>"{title}"</span> did not return any data.
      </div>
      <div className="list-suggestions">
        <div className="title-list">
          <span>Suggestions:</span>
        </div>
        <ul className="list">
          <li>Make sure all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try fewer words.</li>
          <li>
            🤔 Still no results?{' '}
            <span
              className="no-result-help"
              onClick={() => {
                if (process.env.REACT_APP_INTERCOM_ID) {
                  window.Intercom('show');
                }
                track('No result help', { Search: title });
              }}
            >
              Click here for help!
            </span>
          </li>
        </ul>
      </div>
      <div className="tip-wrapper">
        <span>Tip:</span> For example, "how start a blog" instead of "how to
        start a blog in 30 minutes."
      </div>
    </div>
  );
}

export function KeywordResults({
  search,
  keywordData,
  updateQuestions,
  setSearch,
  originKey,
  user,
  selectedRegion,
}) {
  const [questions, setQuestions] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [aiDataAnalysisContent, setAiDataAnalysisContent] = useState('');
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const page_size = 4;
  const alert = useAlert();
  const request = useSentData();
  let half_posts;

  // Get user preferences from Redux store
  const webAccessEnabled = useSelector(
    state => state.auth.user.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );
  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );

  if (keywordData.top_posts) {
    half_posts = Math.round(keywordData.top_posts.length / 2);
  } else {
    half_posts = 0;
  }

  const novaAIEnabled =
    webAccessEnabled || hasTrialSubscription || smartWebNavigatorAccess;

  useEffect(() => {
    setSuggestedKeywords(keywordData.suggested_keywords || []);
  }, [keywordData.suggested_keywords]);

  useEffect(() => {
    setQuestions(keywordData.top_questions || []);
  }, [keywordData.top_questions]);

  const getAiDataAnalysis = async () => {
    track('Keyword analysis', {
      Search: search,
      Clicked: 'explain keyword data',
    });
    const res = await request.send('/api/ai-data-analysis', {
      phrase: search,
      data: keywordData,
    });
    setAiDataAnalysisContent(res.content);
    setHasNewMessage(true);
  };

  const renderKeywordIdeasSection = () => {
    return (
      <div className="keyword-ideas main-card form-card">
        <h3>
          Keyword Ideas
          <Tip text="Additional keywords that are relevant and related to your input keyword are important for SEO purposes.<br/><br/><b>Tip:</b> Click on keyword for more details." />
        </h3>
        <div>
          {suggestedKeywords && suggestedKeywords.length ? (
            <div>
              <div className="keyword-ideas__wrapper">
                {suggestedKeywords.map((keywordObj, i) => (
                  <KeywordItem
                    keyword={
                      typeof keywordObj === 'string'
                        ? keywordObj
                        : keywordObj.keyword
                    }
                    keywordData={keywordObj}
                    key={i}
                    onclick={setSearch}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    );
  };

  const renderTopQuestionsSection = () => {
    return (
      <div className="questions main-card form-card">
        <h3>
          Top Questions People Ask
          <Tip text="The top questions readers are asking relating to this keyword.<br/><br/><b>Tip:</b> Answering these questions in your post will increase your ranking and brand awareness." />
        </h3>
        <div>
          {questions && questions.length ? (
            <div>
              <div className="questions-wrapper">
                {questions.map((question, i) => (
                  <QuestionItem
                    title={
                      typeof question === 'string' ? question : question.keyword
                    }
                    key={i}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="keywords-component">
      <div className="suggested-keywords inner">
        <>
          <Popover className="relative w-24 mx-auto">
            <Popover.Button
              onClick={() => novaAIEnabled && setHasNewMessage(false)}
              className={`rounded-md focus:outline-none ${
                novaAIEnabled ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={!novaAIEnabled}
            >
              <Tooltip title="Nova AI's Keyword Research Assistance" arrow>
                <img
                  src={novaIcon}
                  alt="Nova"
                  className={`w-24 h-24 rounded-full ${
                    novaAIEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                />
              </Tooltip>
            </Popover.Button>
            {hasNewMessage && (
              <div className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                1
              </div>
            )}
            <Popover.Panel side="right">
              <div
                style={{ width: '40vw' }}
                className="absolute z-50 rounded-lg overflow-y-auto border border-purple-400"
              >
                <div className="bg-white static w-full text-center">
                  <NovaMessage message={aiDataAnalysisContent} />
                </div>
              </div>
            </Popover.Panel>
          </Popover>
          <div className="w-full flex justify-center mt-2">
            <button
              className={`w-48 mx-auto inline-flex justify-center px-2 py-2 md:px-6 md:py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ${
                novaAIEnabled ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={request.loading || !novaAIEnabled}
              onClick={getAiDataAnalysis}
            >
              {request.loading ? (
                <>
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="mr-2 animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                  </svg>
                  Analyzing...
                </>
              ) : (
                'Explain Keyword data'
              )}
            </button>
          </div>
        </>
        {originKey.length ? (
          <div style={{ marginTop: keywordData.results === null ? '1rem' : 0 }}>
            <span className="title">Original keyword:&nbsp;</span>
            <span className="suggested-item">
              <span
                className={`${setSearch ? 'cursor-pointer' : ''}`}
                onClick={e => setSearch && setSearch(e, originKey)}
              >
                {originKey}
              </span>
            </span>
          </div>
        ) : null}
      </div>

      <div className="analyzer-results">
        <ResultItem
          title="Volume"
          complexity={volumeComplexity(keywordData.volume || 0)}
          value={keywordData.volume || 0}
          tip="The average number of times this keyword phrase is searched each month.<br/><br><b>Tip:</b> The minimum volume recommended to write a post is 50. Below 50 may not get enough traffic to your website. Above 500,000 there's too many people searching the keyword and it's harder to find your website. Stay out of the red!"
        />
        <ResultItem
          title="Competition"
          complexity={competitionComplexity(keywordData.competition || 0)}
          value={keywordData.competition ? keywordData.competition * 100 : 0}
          tip="The estimated competition level for paid search. Know how difficult it is to pay for traffic instead of getting targeted traffic to a website organically.<br/><br/><b>Tip:</b> The lower the number, the lower the cost to advertise on search engines."
        />
        <ResultItem
          title="Difficulty"
          complexity={difficultyComplexity(keywordData.difficulty || 0)}
          value={keywordData.difficulty || 0}
          tip="The difficulty to outrank a post ranked in the top 10 on Google organically for this keyword.<br/><br/><b>Tip:</b> The lower this number, the easier it is to outrank the competition organically. Rank range is between 0 to 100."
        />
        <ResultItem
          title="Results/SERP"
          complexity={resultsComplexity(keywordData.results || 0)}
          value={keywordData.results || 0}
          tip="The number of results on search engines for this keyword.<br/><br/><b>Tip:</b> The lower this number, the easier it is to get a first page ranking on search engines with a well written and optimized blog post."
        />
      </div>
      <div className="trends main-card form-card">
        <h3>
          Trends
          <Tip text="The average number of searches for this keyword over the last 12 months.<br/><br/><b>Tip:</b> It can take 3 to 6 months for a blog post to reach its maximum ranking potential so plan ahead if you're using a seasonal keyword." />
        </h3>
        <div className="trends-value-wrapper">
          {keywordData.trends &&
          keywordData.trends.map &&
          keywordData.trends.length > 0 ? (
            keywordData.trends.map((trend, i) => (
              <Trend trend={trend} volume={keywordData.volume} key={i} />
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>
      {renderKeywordIdeasSection()}
      {renderTopQuestionsSection()}
      <div className="posts main-card form-card">
        <h3>
          Top 10 Ranking Posts
          <Tip text="Top 10 posts ranked for this keyword.<br/><br/><b>Tip:</b> These are the top 10 posts you are competing against. Study their post, see what they are missing and add that to your post to increase your chances of ranking higher." />
        </h3>
        <div className="posts-wrapper">
          {half_posts > 0 && (
            <div>
              {keywordData.top_posts.map(
                (post, i) =>
                  i < half_posts && (
                    <PostItem
                      id={i}
                      title={post.Domain || post.domain}
                      link={post.Url || post.url}
                      key={i}
                    />
                  )
              )}
            </div>
          )}
          {half_posts > 0 && (
            <div>
              {keywordData.top_posts.map(
                (post, i) =>
                  half_posts <= i && (
                    <PostItem
                      id={i}
                      title={post.Domain || post.domain}
                      link={post.Url || post.url}
                      key={i}
                    />
                  )
              )}
            </div>
          )}
          {half_posts === 0 && <NoData />}
        </div>
      </div>
    </div>
  );
}

function KeywordResearchPage({ user }) {
  const alert = useAlert();
  const history = useHistory();
  const { project_id, id } = useParams();
  const request = useSentData();
  const [isLoading, setIsLoading] = useState(false);
  const [phrase, setPhrase] = useState('');
  const [keywordData, setKeywordData] = useState({});
  const [originKey, setOriginKey] = useState('');
  const [isQualityModalVisible, showQualityModal] = useState(false);
  const [modalData, setModalData] = useState({
    phrase: '',
    analysis_data: {},
    category: 1,
  });
  const [selectedRegion, setSelectedRegion] = useState(
    user?.settings?.database
      ? DATAFORSEO_REGIONS.find(
          r => r.value === user.settings.database.toString()
        ) || DATAFORSEO_REGIONS[0]
      : DATAFORSEO_REGIONS[0]
  );

  // Load existing keyword research data when ID is present
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setPhrase(data.phrase || '');
            setKeywordData(data.analysis_data || {});

            // Set region if available in the data
            if (data.analysis_data && data.analysis_data.database) {
              const region = DATAFORSEO_REGIONS.find(
                r => r.value === data.analysis_data.database.toString()
              );
              if (region) {
                setSelectedRegion(region);
              }
            }
          } else {
            alert.error(
              isolateError(data) || 'Failed to load keyword research data'
            );
          }
          setIsLoading(false);
        });
    }
  }, [id, project_id]);

  // Create a function to transform the DataForSEO response to match the expected format
  const transformKeywordData = (metrics, relatedKeywords, questions, posts) => {
    // Month name mapping
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Convert DataForSEO response to the format expected by the UI components
    return {
      // Basic metrics
      volume: metrics?.searchVolume || 0,
      competition: metrics?.competition || 0,
      difficulty: metrics?.difficulty || 0,
      results: metrics?.seResultsCount || 0,

      // Trend data (monthly search volumes)
      trends:
        metrics?.monthlySearches
          ?.sort((a, b) => {
            // Sort by year first if available, then by month
            // Handle case where year might not be present in older data
            if (
              a.year !== undefined &&
              b.year !== undefined &&
              a.year !== b.year
            ) {
              return a.year - b.year;
            }
            // Convert month string to number for comparison if it's a string
            const aMonth =
              typeof a.month === 'string'
                ? monthNames.indexOf(a.month) + 1
                : a.month;
            const bMonth =
              typeof b.month === 'string'
                ? monthNames.indexOf(b.month) + 1
                : b.month;
            return aMonth - bMonth;
          })
          // Take the most recent 12 months (or less if not enough data)
          .slice(-12)
          .map(item => {
            // Calculate the trend percentage (scale to 100%)
            let trendPercentage = 0;
            if (metrics.searchVolume > 0) {
              // Calculate percentage based on highest monthly value rather than average
              const maxVolume = Math.max(
                ...metrics.monthlySearches
                  .slice(-12) // Use the same 12 months for max calculation
                  .map(m => m.search_volume || 0) // Handle missing search_volume
              );
              trendPercentage = Math.max(
                5,
                ((item.search_volume || 0) / maxVolume) * 100
              );
            }

            // Convert month to string if it's a number
            const monthName =
              typeof item.month === 'string'
                ? item.month
                : monthNames[item.month - 1];

            return {
              month: monthName,
              year: item.year, // This might be undefined for older data
              trend: item.trend || trendPercentage,
              search_volume: item.search_volume, // Include raw search volume value
            };
          }) || [],

      // Related keywords - preserve all properties instead of just the string
      suggested_keywords: relatedKeywords || [],

      // Top questions
      top_questions:
        questions?.map(q => (typeof q === 'string' ? q : q.keyword)) || [],

      // Top posts
      top_posts:
        posts?.map(post => ({
          Domain: post.domain || '',
          Url: post.url || '',
          Title: post.title || '',
        })) || [],
    };
  };

  const fetchKeywordData = async () => {
    if (!phrase) return;

    setIsLoading(true);
    try {
      // Fetch all the data we need
      const [metrics, relatedKeywords, questions, posts] = await Promise.all([
        dataForSeoService.getKeywordMetrics(
          phrase,
          selectedRegion.value, // Use selected region's location code
          selectedRegion.language_code // Use selected region's language code
        ),
        dataForSeoService.getRelatedKeywords(
          phrase,
          selectedRegion.value, // Use selected region's location code
          selectedRegion.language_code, // Use selected region's language code
          ITEMS_TO_SHOW // Static limit instead of variable
        ),
        dataForSeoService.getTopQuestions(
          phrase,
          selectedRegion.value, // Use selected region's location code
          selectedRegion.language_code, // Use selected region's language code
          ITEMS_TO_SHOW // Static limit instead of variable
        ),
        dataForSeoService.getTopRankingPosts(
          phrase,
          selectedRegion.value, // Use selected region's location code
          selectedRegion.language_code, // Use selected region's language code
          ITEMS_TO_SHOW // Static limit instead of variable
        ),
      ]);

      // Transform data to match expected format
      const transformedData = transformKeywordData(
        metrics,
        relatedKeywords,
        questions,
        posts
      );

      setKeywordData(transformedData);
    } catch (error) {
      alert.error(isolateError(error) || 'Failed to fetch keyword data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = (e, val) => {
    e.preventDefault();

    if (val) {
      if (!originKey) setOriginKey(phrase);
      if (originKey === val) setOriginKey('');
      setPhrase(val);
    } else {
      val = phrase;
    }

    track('Keyword analysis', { Search: val, Clicked: 'search' });

    if (!val) return;

    fetchKeywordData();
  };

  const handleShowQualityModal = () => {
    track('Keyword analysis', { Search: phrase, Clicked: 'new post' });

    // Ensure we have valid data structure for the modal
    const newModalData = {
      phrase,
      analysis_data: {
        ...keywordData,
        // Ensure these properties exist to prevent undefined errors
        top_questions: keywordData.top_questions || [],
        top_related: keywordData.suggested_keywords || [], // Map suggested_keywords to top_related for compatibility
      },
      category: 1, // Category 1 is for keyword analyzer
    };

    setModalData(newModalData);
    showQualityModal(true);
  };

  const closeQualityModal = () => {
    showQualityModal(false);
  };

  const submitPost = () => {
    track('Keyword analysis', { Search: phrase, Clicked: 'save keyword' });

    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/keyword-analyzer`;
      method = 'put';
    }

    const data = {
      phrase: phrase,
      analysis_data: keywordData,
    };

    request.send(url, data, method).then(resData => {
      if (!resData.isAxiosError) {
        history.push(`/project/${project_id}/keywords`);
      } else {
        alert.error(isolateError(resData));
      }
    });
  };

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="new-post-component -mx-8 -my-8">
        {isQualityModalVisible && (
          <QualityConfirmationModal
            id={id}
            project_id={project_id}
            data={modalData}
            opened={isQualityModalVisible}
            close={closeQualityModal}
          />
        )}
        <div>
          <div
            className={classNames('inner', {
              'not-empty': Object.keys(keywordData).length > 0,
            })}
          >
            <h1>Keyword Analyzer</h1>
            <h2>Enter your topic or keyword & we'll take care of the rest.</h2>

            <div>
              <form
                className="mt-6 sm:flex sm:items-center"
                onSubmit={handleSearchSubmit}
              >
                <label htmlFor="keyword" className="sr-only">
                  Keyword
                </label>
                <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    className="focus:ring-purple-500 focus:border-purple-500 block w-full pr-32 sm:text-md border-gray-300 rounded-md h-12"
                    placeholder="Enter a topic or keyword phrase that is 3 to 5 words (e.g. how start a blog)"
                    value={phrase}
                    onChange={e => setPhrase(e.target.value.toLowerCase())}
                    data-hj-allow
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <span className="h-4 w-px bg-gray-200" aria-hidden="true" />
                    <label htmlFor="region" className="sr-only">
                      Region
                    </label>
                    {DATAFORSEO_REGIONS.length > 0 && (
                      <RegionFormSelect
                        regions={DATAFORSEO_REGIONS}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                  <button
                    id="submit-keyword"
                    disabled={isLoading}
                    type="submit"
                    className={classNames(
                      'cursor-pointer bg-purple-600 hover:bg-purple-700 focus:ring-purple-500',
                      'w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-xl'
                    )}
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
            {isLoading && (
              <div className="loading-wrapper">
                <Loading />
                <span>
                  We're analyzing your keyword. This can take up to 1 minute.
                </span>
              </div>
            )}
          </div>
        </div>

        {Object.keys(keywordData).length > 0 && !isLoading && (
          <>
            <ReactTooltip
              place={window.innerWidth < 768 ? 'left' : 'top'}
              class="tooltip-for-templates"
              type="light"
              effect="solid"
              html={true}
              border={false}
            />
            <KeywordResults
              search={phrase}
              keywordData={keywordData}
              originKey={originKey}
              user={user}
              selectedRegion={selectedRegion}
              updateQuestions={questions => {
                setKeywordData({
                  ...keywordData,
                  top_questions: questions,
                });
              }}
              setSearch={(e, val) => {
                handleSearchSubmit(e, val);
              }}
            />

            {(user.is_owner || user.is_manager || user.is_editor) && (
              <div className="action-buttons-container">
                <button
                  className="action-button save-keyword"
                  disabled={
                    Object.keys(keywordData).length === 0 ||
                    isLoading ||
                    request.loading
                  }
                  onClick={submitPost}
                >
                  Save Keyword
                </button>
                {id && keywordData.body ? (
                  <Link
                    to={`/project/${project_id}/post-builder/${id}`}
                    className="action-button new-post"
                  >
                    Edit Post
                  </Link>
                ) : (
                  <button
                    className="action-button new-post"
                    disabled={
                      Object.keys(keywordData).length === 0 ||
                      isLoading ||
                      request.loading
                    }
                    onClick={handleShowQualityModal}
                  >
                    New Post
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(KeywordResearchPage);
