import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useAlert } from 'react-alert';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';

import './NewPost.scss';
import useSentData from '../services/useSentData';
import Loading from '../components/Loading';
import { isolateError } from '../utils/api';
import ResultItem from './NewPost/ResultItem';
import QuestionItem from './NewPost/QuestionItem';
import KeywordItem from './NewPost/KeywordItem';
import PostItem from './NewPost/PostItem';
import Trend from './NewPost/Trend';
import {
  competitionComplexity,
  difficultyComplexity,
  resultsComplexity,
  volumeComplexity,
} from '../utils/keywords';
import Tip from '../components/Tip';
import RegionFormSelect from '../components/form/RegionFormSelect';
import backButton from '../styles/images/back_button.svg';
import { track } from '../services/track';
import { chooseOption } from '../services/api';
import QualityConfirmationModal from '../pages/NewPost/QualityConfirmationModal';
import REGIONS from '../utils/regions';
import SemrushBadge from '../components/semrush-badge/semrush-badge';
import ShowlessButton from '../components/keyword-analyzer/showless-button';
import ShowmoreButton from '../components/keyword-analyzer/showmore-button';

import SemrushFullBackLogo from '../styles/images/logo-semrush-full-black.svg';
import { Popover } from '@headlessui/react';
import Tooltip from '@material-ui/core/Tooltip';
import novaIcon from '../styles/images/nova-onboarding.svg';
import NovaMessage from './NewPost/NovaMessage';
import { ExclamationIcon } from '@heroicons/react/solid';
import NewWrapper from '../components/NewWrapper';
import NewHeader from '../components/NewHeader';

function NoData() {
  return <div className="no-data">No data available at this time.</div>;
}

function NoResult(title) {
  return (
    <div className="no-result-wrapper">
      <div className="title">
        Your search: <span>"{title}"</span> did not return any data.
      </div>
      <div className="list-suggestions">
        <div className="title-list">
          <span>Suggestions:</span>
        </div>
        <ul className="list">
          <li>Make sure all words are spelled correctly.</li>
          <li>Try different keywords.</li>
          <li>Try fewer words.</li>
          <li>
            🤔 Still no results?{' '}
            <span
              className="no-result-help"
              onClick={() => {
                if (process.env.REACT_APP_INTERCOM_ID) {
                  window.Intercom('show');
                }
                track('No result help', { Search: title });
              }}
            >
              Click here for help!
            </span>
          </li>
        </ul>
      </div>
      <div className="tip-wrapper">
        <span>Tip:</span> For example, "how start a blog" instead of "how to
        start a blog in 30 minutes."
      </div>
    </div>
  );
}

export function Keywords({
  search,
  keywords,
  updateQuestions,
  setSearch,
  originKey,
  user,
}) {
  const [questions, setQuestions] = React.useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = React.useState([]);
  const [aiDataAnalysisContent, setAiDataAnalysisContent] = React.useState('');
  const [hasNewMessage, setHasNewMessage] = React.useState(false);
  const page_size = 4;
  const [limitQuestions, setLimitQuestions] = React.useState(4);
  const request = useSentData();
  const alert = useAlert();
  let half_posts;
  const [limitKeywords, setLimitKeywords] = React.useState(10);
  const [getKeywords, setGetKeywords] = useState(false);
  const [getQuestions, setGetQuestions] = useState(false);
  const isSemrushConnected = useSelector(
    state => state.auth.user.isSemrushConnected
  );
  const webAccessEnabled = useSelector(
    state => state.auth.user.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );

  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );

  if (keywords.top_posts) {
    half_posts = Math.round(keywords.top_posts.length / 2);
  } else {
    half_posts = 0;
  }

  const novaAIEnabled =
    webAccessEnabled || hasTrialSubscription || smartWebNavigatorAccess;

  useEffect(() => {
    setSuggestedKeywords(keywords.suggested_keywords);
  }, [keywords.suggested_keywords]);

  useEffect(() => {
    setQuestions(keywords.top_questions);
  }, [keywords.top_questions]);

  const getAiDataAnalysis = async () => {
    track('Keyword analysis', {
      Search: search,
      Clicked: 'explain keyword data',
    });
    const res = await request.send('/api/ai-data-analysis', {
      phrase: search,
      data: keywords,
    });
    setAiDataAnalysisContent(res.content);
    setHasNewMessage(true);
  };

  const renderKeywordIdeasSection = () => {
    const showMoreKeywords = () => {
      setGetKeywords(true);
      track('Keyword analysis', { Search: search, Clicked: 'more ideas' });
      request
        .send('/api/semrush/keyword-ideas', {
          // If semrush is not connected, only show up to 10 results
          limit: isSemrushConnected
            ? limitKeywords + page_size
            : 10 - suggestedKeywords.length,
          keyword_phrase: search,
        })
        .then(data => {
          setGetKeywords(false);
          if (!data.isAxiosError) {
            const filteredData = data?.filter(
              d => !suggestedKeywords.includes(d)
            );
            if (!!filteredData.length) {
              const q = isSemrushConnected
                ? [...suggestedKeywords, ...filteredData]
                : [...suggestedKeywords, ...filteredData].splice(0, 10);
              setSuggestedKeywords(q);
              if (isSemrushConnected) {
                setLimitKeywords(limitKeywords + 5);
              }
            } else {
              alert.info("We don't have more keywords to show");
            }
          } else {
            alert.error(isolateError(data));
          }
        });
    };

    const showLessKeywords = () => {
      const q = suggestedKeywords.splice(0, 10);
      setSuggestedKeywords(q);
      // Reset limitKeywords to default (10)
      setLimitKeywords(10);
    };

    const showSemrushBadge =
      (suggestedKeywords.length >= 10 || isSemrushConnected) && user.is_owner;
    const isShowLessDisabled = getKeywords || suggestedKeywords.length < 10;
    const isShowMoreDisabled =
      getKeywords || (showSemrushBadge && !isSemrushConnected);

    return (
      <div className="keyword-ideas main-card form-card">
        <h3>
          Keyword Ideas
          <Tip text="Additional keywords that are relevant and related to your input keyword are important for SEO purposes.<br/><br/><b>Tip:</b> Click on keyword for more details." />
        </h3>
        <div>
          {suggestedKeywords && suggestedKeywords.length ? (
            <div>
              <div className="keyword-ideas__wrapper">
                {suggestedKeywords.map((keyword, i) => (
                  <KeywordItem keyword={keyword} key={i} onclick={setSearch} />
                ))}
              </div>
              {(user.is_owner || user.is_manager || user.is_editor) && (
                <div
                  className={classNames(
                    'button-wrapper flex items-end flex-col-reverse sm:flex-row gap-y-2 sm:gap-y-0',
                    showSemrushBadge ? 'justify-between' : ''
                  )}
                >
                  {showSemrushBadge && (
                    <SemrushBadge resourceName="Keyword Ideas" />
                  )}
                  <div className="flex gap-x-2">
                    {suggestedKeywords.length > 10 && (
                      <ShowlessButton
                        disabled={isShowLessDisabled}
                        onClick={showLessKeywords}
                        loading={getKeywords}
                      />
                    )}
                    <ShowmoreButton
                      onClick={showMoreKeywords}
                      disabled={isShowMoreDisabled}
                      loading={getKeywords}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    );
  };

  const renderTopQuestionsSection = () => {
    const showMoreQuestions = () => {
      setGetQuestions(true);
      track('Keyword analysis', { Search: search, Clicked: 'more questions' });
      request
        .send('/api/semrush/questions', {
          limit: isSemrushConnected
            ? limitQuestions + page_size
            : 10 - questions.length,
          keyword_phrase: search,
        })
        .then(data => {
          setGetQuestions(false);
          if (!data.isAxiosError) {
            const filteredData = data?.filter(d => !questions.includes(d));
            if (!!filteredData.length) {
              const q = isSemrushConnected
                ? [...questions, ...data]
                : [...questions, ...data].splice(0, 10);
              setQuestions(q);
              updateQuestions(q);
              if (isSemrushConnected) {
                setLimitQuestions(limitQuestions + 5);
              }
            } else {
              alert.info("We don't have more questions to show");
            }
          } else {
            alert.error(isolateError(data));
          }
        });
    };

    const showLessQuestions = () => {
      const q = questions.splice(0, 10);
      setQuestions(q);
      updateQuestions(q);
      // Reset limitKeywords to default (10)
      setLimitQuestions(10);
    };

    const showSemrushBadge =
      (questions.length >= 10 || isSemrushConnected) && user.is_owner;
    const isShowLessDisabled = getQuestions || questions.length < 10;
    const isShowMoreDisabled =
      getQuestions || (showSemrushBadge && !isSemrushConnected);

    return (
      <div className="questions main-card form-card">
        <h3>
          Top Questions People Ask
          <Tip text="The top questions readers are asking relating to this keyword.<br/><br/><b>Tip:</b> Answering these questions in your post will increase your ranking and brand awareness." />
        </h3>
        <div>
          {questions && questions.length ? (
            <div>
              <div className="questions-wrapper">
                {questions.map((question, i) => (
                  <QuestionItem title={question} key={i} />
                ))}
              </div>
              {(user.is_owner || user.is_manager || user.is_editor) && (
                <div
                  className={classNames(
                    'button-wrapper flex items-end flex-col-reverse sm:flex-row gap-y-2 sm:gap-y-0',
                    showSemrushBadge ? 'justify-between' : ''
                  )}
                >
                  {showSemrushBadge && (
                    <SemrushBadge resourceName="Keyword Ideas" />
                  )}
                  <div className="flex gap-x-2">
                    {questions.length > 10 && (
                      <ShowlessButton
                        onClick={showLessQuestions}
                        disabled={isShowLessDisabled}
                        loading={getQuestions}
                      />
                    )}
                    <ShowmoreButton
                      onClick={showMoreQuestions}
                      disabled={isShowMoreDisabled}
                      loading={getQuestions}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="keywords-component">
      <div className="suggested-keywords inner">
        {/* {novaAIEnabled && ( */}
        <>
          <Popover className="relative w-24 mx-auto">
            <Popover.Button
              onClick={() => novaAIEnabled && setHasNewMessage(false)}
              className={`rounded-md focus:outline-none ${
                novaAIEnabled ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={
                request.loading || !aiDataAnalysisContent || !novaAIEnabled
              }
            >
              <Tooltip title="Nova AI's Keyword Research Assistance" arrow>
                <img
                  src={novaIcon}
                  alt="Nova"
                  className={`w-24 h-24 rounded-full ${
                    novaAIEnabled ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                />
              </Tooltip>
            </Popover.Button>
            {request.loading && (
              <div className="absolute -top-5 right-1/2">
                <div className="dot-flashing"></div>
              </div>
            )}
            {hasNewMessage && (
              <div className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2">
                1
              </div>
            )}
            <Popover.Panel side="right">
              <div
                style={{ width: '40vw' }}
                className="absolute z-50 rounded-lg overflow-y-auto border border-purple-400"
              >
                <div className="bg-white static w-full text-center">
                  <NovaMessage message={aiDataAnalysisContent} />
                </div>
              </div>
            </Popover.Panel>
          </Popover>
          <div className="w-full flex justify-center mt-2">
            <button
              className={`w-48 mx-auto inline-flex justify-center px-2 py-2 md:px-6 md:py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ${
                novaAIEnabled ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={request.loading || !novaAIEnabled}
              onClick={getAiDataAnalysis}
            >
              Explain Keyword data
            </button>
          </div>
        </>
        {/* )} */}
        {originKey.length ? (
          <div style={{ marginTop: keywords.results === null ? '1rem' : 0 }}>
            <span className="title">Original keyword:&nbsp;</span>
            <span className="suggested-item">
              <span
                className={`${setSearch ? 'cursor-pointer' : ''}`}
                onClick={e => setSearch && setSearch(e, originKey)}
              >
                {originKey}
              </span>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="analyzer-results">
        <ResultItem
          title="Volume"
          complexity={volumeComplexity(keywords.volume)}
          value={keywords.volume}
          tip="The average number of times this keyword phrase is searched each month.<br/><br><b>Tip:</b> The minimum volume recommended to write a post is 50. Below 50 may not get enough traffic to your website. Above 500,000 there's too many people searching the keyword and it's harder to find your website. Stay out of the red!"
        />
        <ResultItem
          title="Competition"
          complexity={competitionComplexity(keywords.competition)}
          value={keywords.competition}
          tip="The estimated competition level for paid search. Know how difficult it is to pay for traffic instead of getting targeted traffic to a website organically.<br/><br/><b>Tip:</b> The lower the number, the lower the cost to advertise on search engines."
        />
        <ResultItem
          title="Difficulty"
          complexity={difficultyComplexity(keywords.difficulty)}
          value={keywords.difficulty}
          tip="The difficulty to outrank a post ranked in the top 10 on Google organically for this keyword.<br/><br/><b>Tip:</b> The lower this number, the easier it is to outrank the competition organically. Rank range is between 0 to 100."
        />
        <ResultItem
          title="Results/SERP"
          complexity={resultsComplexity(keywords.results)}
          value={keywords.results}
          tip="The number of results on search engines for this keyword.<br/><br/><b>Tip:</b> The lower this number, the easier it is to get a first page ranking on search engines with a well written and optimized blog post."
        />
      </div>
      <div className="trends main-card form-card">
        <h3>
          Trends
          <Tip text="The average number of searches for this keyword over the last 12 months.<br/><br/><b>Tip:</b> It can take 3 to 6 months for a blog post to reach its maximum ranking potential so plan ahead if you're using a seasonal keyword." />
        </h3>
        <div className="trends-value-wrapper">
          {keywords.trends &&
          keywords.trends.map &&
          keywords.trends.length > 0 ? (
            keywords.trends.map((trend, i) => (
              <Trend trend={trend} volume={keywords.volume} key={i} />
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>
      {renderKeywordIdeasSection()}
      {renderTopQuestionsSection()}
      <div className="posts main-card form-card">
        <h3>
          Top 10 Ranking Posts
          <Tip text="Top 10 posts ranked for this keyword.<br/><br/><b>Tip:</b> These are the top 10 posts you are competing against. Study their post, see what they are missing and add that to your post to increase your chances of ranking higher." />
        </h3>
        <div className="posts-wrapper">
          {half_posts > 0 && (
            <div>
              {keywords.top_posts.map(
                (post, i) =>
                  i < half_posts && (
                    <PostItem
                      id={i}
                      title={post.Domain}
                      link={post.Url}
                      emt={post.EMT}
                      key={i}
                    />
                  )
              )}
            </div>
          )}
          {half_posts > 0 && (
            <div>
              {keywords.top_posts.map(
                (post, i) =>
                  half_posts <= i && (
                    <PostItem
                      id={i}
                      title={post.Domain}
                      link={post.Url}
                      emt={post.EMT}
                      key={i}
                    />
                  )
              )}
            </div>
          )}
          {half_posts === 0 && <NoData />}
        </div>
      </div>
    </div>
  );
}

function NewPost({ user }) {
  const request = useSentData();
  const history = useHistory();
  const { state } = useLocation();
  const { project_id, id } = useParams();

  const [data, setData] = React.useState({
    phrase: '',
    analysis_data: {},
  });
  const [originKey, setOriginKey] = React.useState('');
  const [buttonTile, setButtonTile] = useState('Search');
  const [disabledSearch, setDisableSearch] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(
    user?.settings?.database
      ? chooseOption(REGIONS, user.settings.database)
      : REGIONS[112]
  );
  const [isQualityModalVisible, showQualityModal] = useState(false);
  const [article, setArticle] = useState();
  const alert = useAlert();
  useEffect(() => {
    if (id) {
      request
        .send(`/api/projects/${project_id}/posts/${id}`, null, 'get')
        .then(data => {
          if (!data.isAxiosError) {
            setData(data);

            disableSearch(data);
            setSelectedRegion(
              chooseOption(
                REGIONS,
                data?.analysis_data?.database
                  ? data.analysis_data.database
                  : 'us'
              )
            );
          } else {
            alert.error(isolateError(data));
          }
        });
    } else if (state.page === 'post') {
      setButtonTile('Continue');
    }
    // track(
    //   state === 'post' ? 'Entered create new post' : 'Entered keyword analyzer'
    // );
  }, []);

  const isNotEmpty = !!Object.keys(data.analysis_data).length;
  function disableSearch(data) {
    if (data.category === 1) {
      setButtonTile('Refresh');
      setDisableSearch(true);
    }
  }

  function closeQualityModal() {
    showQualityModal(false);
    setArticle();
  }

  function submitRequest(e, val) {
    if (val) {
      if (!originKey) setOriginKey(data.phrase);
      if (originKey === val) setOriginKey('');
      setData({ ...data, phrase: val });
    } else {
      val = data.phrase;
    }

    track('Keyword analysis', { Search: val, Clicked: 'search' });

    if (e) {
      e.preventDefault();
    }

    if (!val) return;

    request
      .send('/api/semrush', {
        keyword_phrase: val,
        database: selectedRegion.value,
      })
      .then(res => {
        if (!res.isAxiosError) {
          setData({ ...data, analysis_data: res, phrase: val });
          if (state.page === 'post') {
            handleShowQualityModal();
          }
        } else {
          alert.error(isolateError(res));
        }
      });
  }

  function submitPost() {
    let url = `/api/projects/${project_id}/posts`;
    let method = 'post';

    if (id) {
      url += `/${id}/keyword-analyzer`;
      method = 'put';
    }

    request.send(url, data, method).then(() => {
      history.push(`/project/${project_id}/keywords`);
    });
  }

  function handleShowQualityModal() {
    showQualityModal(true);
  }

  return (
    <NewWrapper header={<NewHeader />}>
      <div className="new-post-component -mx-8 -my-8">
        {isQualityModalVisible ? (
          <QualityConfirmationModal
            id={id}
            project_id={project_id}
            data={data}
            article={article}
            opened={isQualityModalVisible}
            close={closeQualityModal}
          />
        ) : (
          ''
        )}
        <div>
          {/* <button className="clear-button" onClick={() => history.push('/')}>
          <img src={backButton} alt="" />
        </button> */}
          <div
            className={classNames('inner', {
              'not-empty': isNotEmpty,
            })}
          >
            <h1>{state?.page === 'post' ? 'New Post' : 'Keyword Analyzer'}</h1>
            {/* <h1>New Post</h1> */}
            <h2>
              {id
                ? ''
                : "Enter your topic or keyword & we'll take care of the rest."}
            </h2>
            {/* <div className="new-post-input-wrapper">
            <form onSubmit={submitRequest}>
              <input
                id="keyword"
                placeholder="Enter a keyword phrase that is 3 to 5 words (e.g. how start a blog)"
                value={data.phrase}
                disabled={
                  disabledSearch ||
                  (!user.is_owner && !user.is_manager && !user.is_editor)
                }
                onChange={e =>
                  setData({ ...data, phrase: e.target.value.toLowerCase() })
                }
              />
              <button
                id="submit-keyword"
                disabled={
                  request.loading ||
                  (!user.is_owner && !user.is_manager && !user.is_editor)
                }
                className={classNames(
                  !user.is_owner && !user.is_manager && !user.is_editor
                    ? 'cursor-default bg-gray-300'
                    : 'cursor-pointer bg-purple-600 hover:bg-purple-700 focus:ring-purple-500',
                  'w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-xl'
                )}
              >
                {buttonTile}
              </button>
            </form>
          </div> */}
            {state.page !== 'post' && (
              <div>
                <div className="flex items-center justify-center gap-x-">
                  <p className="powered">Powered by</p>
                  <img src={SemrushFullBackLogo} className="h-12" />
                </div>
                {/* <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Attention needed
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>
                        We are currently transitioning to a new provider for our
                        keyword data.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            )}
            <div>
              <form
                className="mt-6 sm:flex sm:items-center"
                onSubmit={submitRequest}
              >
                <label htmlFor="emails" className="sr-only">
                  Database
                </label>
                <div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
                  <input
                    type="text"
                    name="keyword"
                    id="keyword"
                    className="focus:ring-purple-500 focus:border-purple-500 block w-full pr-32 sm:text-md border-gray-300 rounded-md h-12"
                    placeholder="Enter a topic or keyword phrase that is 3 to 5 words (e.g. how start a blog)"
                    value={data.phrase}
                    disabled={
                      disabledSearch ||
                      (!user.is_owner && !user.is_manager && !user.is_editor)
                    }
                    onChange={e =>
                      setData({ ...data, phrase: e.target.value.toLowerCase() })
                    }
                    data-hj-allow
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <span className="h-4 w-px bg-gray-200" aria-hidden="true" />
                    <label htmlFor="role" className="sr-only">
                      Region
                    </label>
                    {REGIONS.length > 0 && (
                      <RegionFormSelect
                        regions={REGIONS}
                        selectedRegion={selectedRegion}
                        setSelectedRegion={setSelectedRegion}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
                  <button
                    id="submit-keyword"
                    disabled={
                      request.loading ||
                      (!user.is_owner && !user.is_manager && !user.is_editor)
                    }
                    type="submit"
                    // className="block w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    className={classNames(
                      !user.is_owner && !user.is_manager && !user.is_editor
                        ? 'cursor-default bg-gray-300'
                        : 'cursor-pointer bg-purple-600 hover:bg-purple-700 focus:ring-purple-500',
                      'w-full inline-flex justify-center px-2.5 py-1.5 md:px-6 md:py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-xl'
                    )}
                  >
                    {buttonTile}
                  </button>
                </div>
              </form>
            </div>
            {request.loading && (
              <div className="loading-wrapper">
                <Loading />
                <span>
                  We're analyzing your keyword. This can take up to 1 minute.
                </span>
              </div>
            )}
          </div>
        </div>
        {isNotEmpty && !request.loading && state.page !== 'post' && (
          <>
            <ReactTooltip
              place={window.innerWidth < 768 ? 'left' : 'top'}
              class="tooltip-for-templates"
              type="light"
              effect="solid"
              html={true}
              border={false}
            />
            <Keywords
              search={data.phrase}
              keywords={data.analysis_data}
              originKey={originKey}
              user={user}
              updateQuestions={questions => {
                data.analysis_data.top_questions = questions;
                setData(data);
              }}
              setSearch={(e, val) => {
                submitRequest(e, val);
              }}
            />
          </>
        )}
        {(user.is_owner || user.is_manager || user.is_editor) &&
          (state.page === 'analyzer' || id) && (
            <div className="action-buttons-container">
              <button
                className="action-button save-keyword"
                disabled={!isNotEmpty || request.loading}
                onClick={() => {
                  if (data.category === 1) {
                    history.push(`/project/${project_id}/dashboard`);
                  } else {
                    submitPost();
                  }
                }}
              >
                Save Keyword
              </button>
              {data.body ? (
                <Link
                  to={`/project/${project_id}/post-builder/${id}`}
                  className="action-button new-post"
                >
                  Edit Post
                </Link>
              ) : (
                <button
                  className="action-button new-post"
                  disabled={!isNotEmpty || request.loading}
                  onClick={() => handleShowQualityModal()}
                >
                  New Post
                </button>
              )}
            </div>
          )}
      </div>
    </NewWrapper>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, null)(NewPost);
