import React, { useState, useEffect } from 'react';
import { TrashIcon, CheckCircleIcon } from '@heroicons/react/outline';
import http from '../services/http';
import { useAlert } from 'react-alert';

const DocumentManager = ({ onSelect, selectedFiles = [], maxFiles = 5 }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  useEffect(() => {
    loadDocuments();
  }, []);

  const loadDocuments = async () => {
    try {
      const response = await http.get('/api/files?is_document=true');
      if (response.data) {
        // Handle paginated response
        const docs = response.data.results || response.data;
        setDocuments(Array.isArray(docs) ? docs : []);
      }
    } catch (error) {
      console.error('Error loading documents:', error);
      alert.error('Failed to load documents');
      setDocuments([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async id => {
    try {
      await http.delete(`/api/files/${id}`);
      alert.success('Document deleted successfully');
      // Remove from selected files if it was selected
      if (selectedFiles.includes(id)) {
        onSelect(selectedFiles.filter(fileId => fileId !== id));
      }
      // Refresh the document list
      loadDocuments();
    } catch (error) {
      console.error('Error deleting document:', error);
      alert.error('Failed to delete document');
    }
  };

  const handleSelect = id => {
    if (selectedFiles.includes(id)) {
      onSelect(selectedFiles.filter(fileId => fileId !== id));
    } else {
      if (selectedFiles.length >= maxFiles) {
        alert.error(`You can select up to ${maxFiles} files`);
        return;
      }
      onSelect([...selectedFiles, id]);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="max-h-[60vh] overflow-y-auto pr-2 space-y-2">
        {documents.length === 0 ? (
          <p className="text-gray-500 text-center py-4">
            No documents uploaded yet
          </p>
        ) : (
          documents.map(doc => (
            <div
              key={doc.id}
              className="flex items-center justify-between p-3 bg-white border rounded-lg hover:bg-gray-50 transition-colors duration-200"
            >
              <div className="flex items-center space-x-3 flex-1">
                <button
                  onClick={() => handleSelect(doc.id)}
                  className={`flex items-center justify-center w-5 h-5 rounded-full border ${
                    selectedFiles.includes(doc.id)
                      ? 'bg-purple-600 border-purple-600'
                      : 'border-gray-300 hover:border-purple-500'
                  }`}
                >
                  {selectedFiles.includes(doc.id) && (
                    <CheckCircleIcon className="w-5 h-5 text-white" />
                  )}
                </button>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {doc.original_name}
                  </p>
                  <p className="text-xs text-gray-500">
                    {new Date(doc.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleDelete(doc.id)}
                className="ml-2 p-1 text-gray-400 hover:text-red-500 transition-colors duration-200"
              >
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DocumentManager;
