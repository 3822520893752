import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { UploadIcon, DocumentTextIcon } from '@heroicons/react/outline';
import useSentData from '../services/useSentData';
import DocumentManager from './DocumentManager';
import './DocumentUploadModal.scss';

const DocumentUploadModal = ({
  open,
  onClose,
  onUploadComplete,
  selectedFileIds = [],
  onFileSelect,
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const [view, setView] = useState('upload'); // 'upload' or 'manage'
  const request = useSentData();

  const handleDrop = async e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileInput = e => {
    const file = e.target.files[0];
    handleFile(file);
  };

  const handleFile = async file => {
    if (!file) return;

    // Check file type
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
    ];
    if (!allowedTypes.includes(file.type)) {
      setError('Please upload a PDF, Word document, or text file');
      return;
    }

    // Check file size (10MB limit)
    if (file.size > 10 * 1024 * 1024) {
      setError('File size should not exceed 10MB');
      return;
    }

    setError('');
    setFileName(file.name);

    // Create preview for PDF files
    if (file.type === 'application/pdf') {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'document');

      const response = await request.send('/api/files', formData, 'POST', {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      if (!response.isAxiosError) {
        if (response.content_text) {
          // Content extraction succeeded
          onUploadComplete(response);
          // Automatically select the newly uploaded file
          if (onFileSelect && response.id) {
            onFileSelect([...selectedFileIds, response.id]);
          }
          setView('manage'); // Switch to manage view after successful upload
        } else {
          // File uploaded but content extraction failed
          setError(
            'File was uploaded but text content could not be extracted. Please try a different file or format.'
          );
          onUploadComplete(response);
        }
      } else {
        const errorMessage =
          response.response?.data?.detail || 'Failed to upload document';
        setError(errorMessage);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setError('Failed to upload document. Please try again.');
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const handleClose = () => {
    setUploading(false);
    setUploadProgress(0);
    setPreviewUrl(null);
    setFileName('');
    setError('');
    setView('upload');
    onClose();
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative bg-white rounded-lg w-full max-w-2xl mx-auto shadow-xl">
          {/* Header */}
          <div className="sticky top-0 bg-white px-6 py-4 border-b">
            <Dialog.Title className="text-lg font-medium text-gray-900 flex items-center justify-between">
              <span>Document Manager</span>
              <div className="flex space-x-2">
                <button
                  onClick={() => setView('upload')}
                  className={`px-3 py-1 rounded-md text-sm ${
                    view === 'upload'
                      ? 'bg-purple-600 text-white'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Upload
                </button>
                <button
                  onClick={() => setView('manage')}
                  className={`px-3 py-1 rounded-md text-sm ${
                    view === 'manage'
                      ? 'bg-purple-600 text-white'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  Manage Files
                </button>
              </div>
            </Dialog.Title>
          </div>

          {/* Content */}
          <div className="p-6">
            {view === 'upload' ? (
              <div
                className="upload-area"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <input
                  type="file"
                  onChange={handleFileInput}
                  accept=".pdf,.doc,.docx,.txt"
                  style={{ display: 'none' }}
                  id="file-input"
                />
                <label htmlFor="file-input" className="upload-label">
                  <div className="upload-icon">
                    <UploadIcon className="h-12 w-12 text-gray-400" />
                  </div>
                  <div className="upload-text">
                    <p>Click or drag file to this area to upload</p>
                    <p className="text-sm text-gray-500">
                      Support for PDF, Word documents, and text files
                    </p>
                  </div>
                </label>

                {fileName && (
                  <div className="mt-4 text-sm text-gray-600">
                    Selected file: {fileName}
                  </div>
                )}

                {uploading && (
                  <div className="mt-4">
                    <div className="relative pt-1">
                      <div className="overflow-hidden h-2 text-xs flex rounded bg-purple-200">
                        <div
                          style={{ width: `${uploadProgress}%` }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500 transition-all duration-300"
                        />
                      </div>
                      <div className="text-right mt-1 text-sm text-gray-600">
                        {uploadProgress}%
                      </div>
                    </div>
                  </div>
                )}

                {error && (
                  <div className="mt-4 text-sm text-red-600">{error}</div>
                )}

                {previewUrl && (
                  <div className="mt-4 border rounded-lg overflow-hidden">
                    <iframe
                      src={previewUrl}
                      title="Document Preview"
                      className="w-full h-[300px]"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="min-h-[300px] max-h-[60vh]">
                <DocumentManager
                  selectedFiles={selectedFileIds}
                  onSelect={onFileSelect}
                  maxFiles={5}
                />
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="sticky bottom-0 bg-white px-6 py-4 border-t">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={handleClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Close
              </button>
              {view === 'upload' && (
                <button
                  type="button"
                  onClick={() => document.getElementById('file-input').click()}
                  disabled={uploading}
                  className={`px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 ${
                    uploading ? 'cursor-not-allowed' : ''
                  }`}
                >
                  {uploading ? 'Uploading...' : 'Upload'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

DocumentUploadModal.defaultProps = {
  open: false,
  onClose: () => {},
  onUploadComplete: () => {},
  selectedFileIds: [],
  onFileSelect: () => {},
};

export default DocumentUploadModal;
