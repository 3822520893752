import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chat from '../pages/Chat';
import {
  loadConversations,
  loadFavoritePrompts,
  setActiveChatId,
  setChatMessage,
  setConnectToTheWeb,
  setLookInTheEditor,
  setMessages,
  setPanelMode,
  setLoading,
} from '../store/actions/chat.actions';
import {
  PlusIcon,
  XIcon,
  PaperAirplaneIcon,
  MicrophoneIcon,
  LightningBoltIcon,
  ChevronDownIcon,
} from '@heroicons/react/solid';
import {
  StarIcon,
  ClockIcon,
  CogIcon,
  SparklesIcon,
  ClipboardCopyIcon,
  CheckIcon,
  PencilIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import panelCollapse, {
  sidePanelTypes,
} from '../store/actions/side_panel.action';
import Tooltip from '@material-ui/core/Tooltip';
import { Popover, Switch } from '@headlessui/react';
import {
  useSendMessage,
  useSaveChatMessage,
  useSaveOrUpdateConversation,
  useGetChatMessages,
  useDeleteFavoritePrompt,
  useSaveFavoritePrompt,
} from '../utils/chatApi';
import { v4 as uuid } from 'uuid';
import { isolateError } from '../utils/api';
import { useAlert } from 'react-alert';
import { track } from '../services/track';
import useSentData from '../services/useSentData';
import { MessageItem } from '../pages/Chat/MessageItem';
import defaultAvatar from '../styles/images/default-avatar.svg';
import novaIcon from '../styles/images/nova-onboarding.svg';

// Direct custom component for builder
const ChatPanel = React.forwardRef((props, ref) => {
  const { sidePanelValue, isPanelCollapsed } = useSelector(
    state => state.panel
  );
  const dispatch = useDispatch();
  const messages = useSelector(state => state.chat.messages);
  const chatId = useSelector(state => state.chat.activeChatId);
  const chatMessage = useSelector(state => state.chat.chatMessage);
  const isLoading = useSelector(state => state.chat.loading);
  const lookInTheEditor = useSelector(state => state.chat.lookInTheEditor);
  const connectToTheWeb = useSelector(state => state.chat.connectToTheWeb);
  const conversations = useSelector(state => state.chat.conversations);
  const favoritePrompts = useSelector(state => state.chat.favoritePrompts);

  // Message action states
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedText, setEditedText] = useState('');

  // User permissions
  const webAccessEnabled = useSelector(
    state => state.auth?.user?.web_access_enabled
  );
  const smartWebNavigatorAccess = useSelector(
    state => state.auth?.user?.smart_web_navigator_access
  );
  const hasTrialSubscription = useSelector(
    state => state.auth.user.has_trial_subscription
  );
  const hasAIAgentAccess =
    webAccessEnabled || smartWebNavigatorAccess || hasTrialSubscription;

  // Input handling
  const [inputText, setInputText] = useState('');
  const textareaRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  // API hooks
  const { sendMessage } = useSendMessage();
  const { saveChatMessage } = useSaveChatMessage();
  const { saveOrUpdateConversation } = useSaveOrUpdateConversation();
  const { getChatMessages } = useGetChatMessages();
  const { deleteFavoritePrompt } = useDeleteFavoritePrompt();
  const { saveFavoritePrompt } = useSaveFavoritePrompt();
  const alert = useAlert();

  const [suggestions] = useState([
    'Conduct in-depth research on [keyword] and provide a concise, detailed report',
    'Could you analyze this keyword [keyword]',
    'What are some related keywords for [keyword]',
    'Could you suggest keywords for [keyword]',
    'Write a blog outline for me about [keyword]',
    "Can you check if my blog post on [keyword] is SEO optimized? Here's the post: [URL]",
    'Which websites are the main competitors of this site [URL]',
    'What keywords are driving traffic to this site [URL]',
    'Extract the LSI keywords from this post: [URL]',
    'Get the highest-ranking search results for [keyword]',
    'What questions do people ask about [keyword]',
    'Please fact-check this information: [Information]',
    'Create a blog post from this YouTube Video: [URL]',
    'Can you extract the main points from this YouTube Video: [URL]',
  ]);

  // State for voice recording and improving functionality
  const [isVoiceRecording, setIsVoiceRecording] = useState(false);
  const [isImprovingPrompt, setIsImprovingPrompt] = useState(false);

  // Import the useSentData hook to make API requests
  const request = useSentData();

  // Add loading state for chats
  const [isLoadingChat, setIsLoadingChat] = useState(false);

  // Get the user from state to access avatar
  const user = useSelector(state => state.auth.user);
  const userAvatar = user?.preview || defaultAvatar;

  // Check if message is in favorites
  const isMessageFavorite = messageId => {
    return favoritePrompts.findIndex(item => item.id === messageId) >= 0;
  };

  // Handle favorite/unfavorite
  const handleFavorite = async message => {
    const isFavorite = isMessageFavorite(message.id);

    try {
      if (isFavorite) {
        await deleteFavoritePrompt(message.id);
      } else {
        await saveFavoritePrompt(chatId, message);
      }
      dispatch(loadFavoritePrompts());
    } catch (error) {
      console.error('Error handling favorite:', error);
      alert.error('Failed to update favorites');
    }
  };

  // Handle edit message
  const handleEditMessage = messageId => {
    const message = messages.find(msg => msg.id === messageId);
    if (message) {
      setEditingMessageId(messageId);
      setEditedText(message.content);
    }
  };

  // Save edited message
  const handleSaveEdit = async messageId => {
    try {
      // Find message index
      const messageIndex = messages.findIndex(msg => msg.id === messageId);
      if (messageIndex === -1) return;

      // Create updated message
      const updatedMessage = {
        ...messages[messageIndex],
        content: editedText,
      };

      // Update message in the API
      await saveChatMessage(chatId, updatedMessage, true);

      // Update message in UI
      const updatedMessages = [...messages];
      updatedMessages[messageIndex] = updatedMessage;
      dispatch(setMessages(updatedMessages));

      // Reset editing state
      setEditingMessageId(null);
      setEditedText('');
    } catch (error) {
      console.error('Error saving edit:', error);
      alert.error('Failed to save edit');
    }
  };

  // Cancel edit
  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditedText('');
  };

  // Handle message rating (like/dislike)
  const handleRate = async (message, rating) => {
    try {
      // Here we would typically call an API to save the rating
      // For now just track the event
      track('Rate Message', { messageId: message.id, rating });
      alert.success(`Message ${rating === 'up' ? 'liked' : 'disliked'}`);
    } catch (error) {
      console.error('Error rating message:', error);
      alert.error('Failed to rate message');
    }
  };

  // Handle copy message to clipboard
  const handleCopy = content => {
    navigator.clipboard.writeText(content);
    alert.success('Successfully copied to clipboard!');
  };

  // Handle insert message content into editor
  const handleInsertIntoEditor = message => {
    try {
      if (props.editorRef?.current?.editor) {
        // Get formatted HTML content from the message element
        const messageElement = document.getElementById(
          `chat-message-${message.id}`
        );
        let content = message.content;

        if (messageElement) {
          // Use the rendered HTML content if available
          content = messageElement.innerHTML;
        }

        // Insert content at current cursor position
        props.editorRef.current.editor.insertContent(content);
        alert.success('Content inserted into editor');
        track('Insert Content', { Action: 'Inserted from chat' });
      } else {
        alert.error('Editor not available');
      }
    } catch (error) {
      console.error('Error inserting content:', error);
      alert.error('Failed to insert content into editor');
    }
  };

  useEffect(() => {
    // Initialize the chat panel
    dispatch(loadFavoritePrompts());
    dispatch(loadConversations());
    dispatch(setChatMessage(''));
    dispatch(setActiveChatId(''));
    dispatch(setMessages([]));
    dispatch(setPanelMode(true));
    dispatch(setLookInTheEditor(true)); // Always look in editor in builder mode

    // Set a test message to show the send button
    setInputText('Test message');

    return () => {
      dispatch(setPanelMode(false));
    };
  }, []);

  useEffect(() => {
    setInputText(chatMessage);
  }, [chatMessage]);

  // Replace the existing adjustTextareaHeight function with this improved version
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset height temporarily to get proper scrollHeight
      textareaRef.current.style.height = '38px';

      // Get scrollHeight (total content height)
      const scrollHeight = textareaRef.current.scrollHeight;

      // Max height is ~4 rows (38px per row with some margin)
      const maxHeight = 110;

      // Set height with limits
      if (scrollHeight <= maxHeight) {
        // Content fits within max height, so make textarea fit content exactly
        textareaRef.current.style.height = scrollHeight + 'px';
        textareaRef.current.style.overflowY = 'hidden';
      } else {
        // Content exceeds max height, cap height and enable scrolling
        textareaRef.current.style.height = maxHeight + 'px';
        textareaRef.current.style.overflowY = 'auto';
      }
    }
  };

  // Add an effect to scroll textarea to bottom when it's at max height
  useEffect(() => {
    if (textareaRef.current && textareaRef.current.scrollHeight > 110) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [inputText]);

  // Update textarea height whenever input text changes
  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  // Add scroll event listener to show/hide scroll button
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;

    const handleScroll = () => {
      if (messagesContainer) {
        // Show button when scrolled down more than 300px
        const scrollTop = messagesContainer.scrollTop;
        setShowScrollButton(scrollTop > 300);
      }
    };

    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Function to scroll to top
  const scrollToTop = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  // Handle input changes
  const handleInputChange = e => {
    setInputText(e.target.value);
  };

  // Function to reset chat state
  const resetChatState = () => {
    dispatch(setChatMessage(''));
    dispatch(setActiveChatId(''));
    dispatch(setMessages([]));
    setInputText('');
  };

  // Send message function
  const handleSendMessage = async () => {
    if (isLoading || !inputText.trim()) return;

    let currentChatId = chatId;
    const isNewChat = !currentChatId;

    if (!currentChatId) {
      currentChatId = uuid();
      dispatch(setActiveChatId(currentChatId));
    }

    const userMessage = {
      role: 'user',
      content: inputText.trim(),
      created_at: new Date().toISOString(),
    };

    // Track analytics
    track('Chat message', { Message: inputText.trim() });

    // Update UI immediately to show user message
    const updatedMessages = [...messages, userMessage];
    dispatch(setMessages(updatedMessages));

    // Clear input
    setInputText('');
    // Set loading state
    dispatch(setLoading(true));

    try {
      // Save user message to database
      const userMessageId = await saveChatMessage(currentChatId, userMessage);

      if (userMessageId) {
        // Update the message with the ID
        const updatedMessagesWithId = updatedMessages.map((msg, index) =>
          index === updatedMessages.length - 1
            ? { ...msg, id: userMessageId }
            : msg
        );

        dispatch(setMessages(updatedMessagesWithId));

        // Send message to API
        const aiResponse = await sendMessage(
          [...updatedMessagesWithId.map(({ id, created_at, ...rest }) => rest)],
          {
            connectToTheWeb,
            lookInTheEditor: true, // Always look in editor in builder mode
            lookback: props.editorRef?.current?.editor.getContent({
              format: 'text',
            }),
          }
        );

        if (aiResponse) {
          const aiMessage = {
            role: 'assistant',
            content: aiResponse,
            created_at: new Date().toISOString(),
          };

          // Save AI message to database
          const aiMessageId = await saveChatMessage(currentChatId, aiMessage);

          if (aiMessageId) {
            // Update messages with AI response
            const finalMessages = [
              ...updatedMessagesWithId,
              { ...aiMessage, id: aiMessageId },
            ];
            dispatch(setMessages(finalMessages));

            // Save or update the conversation
            await saveOrUpdateConversation(currentChatId, inputText.trim());
          }

          // If this was a new chat, reload conversations
          if (isNewChat) {
            dispatch(loadConversations());
          }
        }
      }
    } catch (error) {
      console.error('Error sending message to API:', error);
      alert.error('Failed to send message. Please try again.');
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Toggle AI Agent
  const toggleConnectToTheWeb = () => {
    if (!hasAIAgentAccess) {
      alert.info('Please upgrade your plan to use the AI Agent feature.');
      return;
    }
    dispatch(setConnectToTheWeb(!connectToTheWeb));
  };

  // Voice input handling
  const toggleVoiceRecording = () => {
    if (
      !('webkitSpeechRecognition' in window) &&
      !('SpeechRecognition' in window)
    ) {
      alert.error('Voice input is not supported in this browser');
      return;
    }

    if (isVoiceRecording) {
      setIsVoiceRecording(false);
      // Stop voice recording logic
      return;
    }

    setIsVoiceRecording(true);

    // Browser speech recognition API
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = event => {
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        }
      }

      if (finalTranscript) {
        setInputText(prevText => prevText + ' ' + finalTranscript);
      }
    };

    recognition.onend = () => {
      setIsVoiceRecording(false);
    };

    recognition.onerror = event => {
      console.error('Speech recognition error', event.error);
      setIsVoiceRecording(false);
    };

    recognition.start();
  };

  // Improve prompt handling
  const handleImprovePrompt = async () => {
    if (!inputText.trim() || isImprovingPrompt) {
      alert.info('Please enter a prompt to improve');
      return;
    }

    setIsImprovingPrompt(true);
    track('Improve Prompt', { Action: 'Started' });

    try {
      const response = await request.send('/api/improve-prompt', {
        prompt: inputText.trim(),
      });

      if (!response.isAxiosError) {
        if (response.success && response.content?.text) {
          setInputText(response.content.text);

          // Force height adjustment after a short delay to ensure the DOM has updated
          setTimeout(() => {
            if (textareaRef.current) {
              textareaRef.current.style.height = '38px';
            }
          }, 50);

          track('Improve Prompt', { Success: true });
        } else {
          alert.error(
            response.message || 'Failed to improve prompt. Please try again.'
          );
          track('Improve Prompt', { Success: false, Error: response.message });
        }
      } else {
        alert.error(isolateError(response));
        track('Improve Prompt', {
          Success: false,
          Error: isolateError(response),
        });
      }
    } catch (error) {
      console.error('Error improving prompt:', error);
      alert.error(
        'An error occurred while improving the prompt. Please try again.'
      );
      track('Improve Prompt', { Success: false, Error: error.message });
    } finally {
      setIsImprovingPrompt(false);
    }
  };

  // Update the useEffect that loads messages to show loading state
  useEffect(() => {
    if (chatId) {
      const fetchChatMessages = async () => {
        try {
          setIsLoadingChat(true);
          const chatMessages = await getChatMessages(chatId);
          if (chatMessages && Array.isArray(chatMessages)) {
            dispatch(setMessages(chatMessages));
          }
        } catch (error) {
          console.error('Error loading chat messages:', error);
          alert.error('Failed to load chat messages');
        } finally {
          setIsLoadingChat(false);
        }
      };

      fetchChatMessages();
    }
  }, [chatId]);

  // Function to scroll to latest message when chat history is loaded
  useEffect(() => {
    if (!isLoadingChat && messages.length > 0) {
      // Scroll to the bottom of the messages container with a slight delay to ensure rendering
      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [isLoadingChat, messages]);

  // Scroll to bottom when loading indicator appears or disappears
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [isLoading]);

  return (
    <div
      className="chat-panel-wrapper w-full"
      id="side-panel"
      style={{ width: 'auto' }}
    >
      <div className="w-full fixed h-full flex flex-col bg-white shadow-xl overflow-hidden">
        <div className="flex flex-col h-full">
          {/* Compact header with consistent layout */}
          <div
            className="flex items-center justify-between px-6 pt-3 pb-2"
            style={{
              borderBottom: '1px solid #e5e7eb',
              padding: '16px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <div className="text-lg font-medium text-gray-900">Chat</div>
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-500 close-button"
              onClick={() => {
                const panel =
                  isPanelCollapsed === false &&
                  sidePanelValue === sidePanelTypes.PLAGIARISM
                    ? false
                    : !isPanelCollapsed;
                dispatch(panelCollapse(sidePanelTypes.CHAT, panel));
              }}
              style={{
                position: 'absolute',
                right: '16px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1000,
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                visibility: 'visible',
                opacity: 1,
              }}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Toolbar */}
          <div className="flex px-4 py-2 gap-3 border-b border-gray-200">
            <Tooltip title="New Chat" arrow>
              <button
                className="p-1 select-none rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-purple-500"
                onClick={() => {
                  resetChatState();
                  // Also update URL if needed
                  track('New Chat', { Action: 'Started' });
                }}
              >
                <PlusIcon className="h-4 w-4 cursor-pointer" />
              </button>
            </Tooltip>

            {/* Add the missing buttons with functionality */}
            <Popover className="relative">
              <Popover.Button className="rounded-md focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-purple-500">
                <Tooltip title="Suggested Prompts" arrow>
                  <div className="p-1 select-none">
                    <SparklesIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-popover">
                  <div className="chat-side-panel-header">
                    Suggested Prompts
                  </div>
                  <div className="chat-side-panel-content">
                    {suggestions.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          dispatch(setChatMessage(item));
                          document.body.click(); // Close popover after selection
                        }}
                        className="chat-side-panel-item"
                      >
                        <div className="text-sm">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="rounded-md focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-purple-500">
                <Tooltip title="Favorite Prompts" arrow>
                  <div className="p-1 select-none">
                    <StarIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-popover">
                  <div className="chat-side-panel-header">Favorite Prompts</div>
                  <div className="chat-side-panel-content">
                    {favoritePrompts.length > 0 ? (
                      favoritePrompts.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            dispatch(setChatMessage(item.content));
                            document.body.click(); // Close popover after selection
                          }}
                          className="chat-side-panel-item"
                        >
                          <div className="text-sm">{item.content}</div>
                        </div>
                      ))
                    ) : (
                      <div className="chat-side-panel-empty">
                        <div className="text-sm">No favorite prompts</div>
                        <div className="chat-side-panel-empty-subtitle">
                          Star messages to add them here
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="rounded-md focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-purple-500">
                <Tooltip title="Chat History" arrow>
                  <div className="p-1 select-none">
                    <ClockIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                </Tooltip>
              </Popover.Button>

              <Popover.Panel>
                <div className="absolute z-10 chat-side-panel-popover">
                  <div className="chat-side-panel-header">Chat History</div>
                  <div className="chat-side-panel-content">
                    {conversations.length > 0 ? (
                      conversations.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            dispatch(setActiveChatId(item.id));
                            // Close the popover after selection
                            document.body.click();
                          }}
                          className={`chat-side-panel-item ${
                            chatId === item.id ? 'active' : ''
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <div className="truncate text-sm pr-2">
                              {item.snippet}
                            </div>
                            {chatId === item.id && (
                              <div className="text-purple-600 flex-shrink-0">
                                <span className="text-xs bg-purple-100 px-1.5 py-0.5 rounded-full">
                                  Active
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="chat-side-panel-empty">
                        <div className="text-sm">No chat history available</div>
                        <div className="chat-side-panel-empty-subtitle">
                          Start a new chat to see it here
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </div>

          {/* Chat container */}
          <div
            className="flex-1 overflow-hidden flex flex-col"
            style={{ height: 'calc(100% - 70px)' }}
          >
            {/* Chat messages */}
            <div
              className="flex-1 overflow-y-auto px-3 relative chat-messages-wrapper"
              id="chat-messages-container"
              ref={messagesContainerRef}
              style={{ width: 'auto' }}
            >
              {isLoadingChat ? (
                <div className="flex justify-center items-center h-full">
                  <div className="flex flex-col items-center">
                    <div
                      className="spinner-border animate-spin inline-block w-6 h-6 border-2 rounded-full text-purple-500 border-current border-b-transparent"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <span className="mt-2 text-sm text-gray-500">
                      Loading messages...
                    </span>
                  </div>
                </div>
              ) : messages.length > 0 ? (
                messages.map((message, index) => (
                  <div
                    key={message.id || index}
                    className={`chat-message ${
                      message.role === 'user'
                        ? 'chat-message-user'
                        : 'chat-message-bot'
                    }`}
                  >
                    <img
                      src={message.role === 'user' ? userAvatar : novaIcon}
                      alt={
                        message.role === 'user' ? 'User Avatar' : 'AI Avatar'
                      }
                      className="w-8 h-8 rounded-full"
                    />
                    <div className="chat-message-content">
                      <div
                        className="message-content-wrapper max-w-full overflow-hidden"
                        style={{ width: '260px', maxWidth: '260px' }}
                      >
                        {editingMessageId === message.id ? (
                          <textarea
                            className="chat-message-edit block shadow-sm sm:text-sm focus:ring-purple-500 focus:border-purple-500 border border-gray-300 rounded-md"
                            value={editedText}
                            onChange={e => setEditedText(e.target.value)}
                            style={{
                              minHeight: '80px',
                              width: '100%',
                              maxWidth: '260px',
                              fontSize: '14px',
                              padding: '8px',
                              resize: 'vertical',
                            }}
                          />
                        ) : typeof message.content === 'string' ? (
                          <MessageItem
                            message={message.content}
                            id={message.id || index}
                          />
                        ) : (
                          <div className="chat-message-text">
                            {JSON.stringify(message.content)}
                          </div>
                        )}
                      </div>
                      <div className="chat-message-meta">
                        <div className="chat-message-actions">
                          {message.role === 'user' ? (
                            <>
                              {editingMessageId === message.id ? (
                                <div className="action-buttons-container">
                                  <Tooltip title="Save" arrow>
                                    <span>
                                      <CheckIcon
                                        className="chat-action-icon h-4 w-4"
                                        onClick={() =>
                                          handleSaveEdit(message.id)
                                        }
                                        style={{ color: '#10b981' }}
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip title="Cancel" arrow>
                                    <span>
                                      <XIcon
                                        className="chat-action-icon h-4 w-4"
                                        onClick={handleCancelEdit}
                                        style={{ color: '#ef4444' }}
                                      />
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="action-buttons-container">
                                  {isMessageFavorite(message.id) ? (
                                    <Tooltip
                                      title="Remove from favorites"
                                      arrow
                                    >
                                      <span>
                                        <StarIconSolid
                                          className="chat-action-icon h-4 w-4 favorite"
                                          onClick={() =>
                                            handleFavorite(message)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Add to favorites" arrow>
                                      <span>
                                        <StarIcon
                                          className="chat-action-icon h-4 w-4"
                                          onClick={() =>
                                            handleFavorite(message)
                                          }
                                        />
                                      </span>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Edit message" arrow>
                                    <span>
                                      <PencilIcon
                                        className="chat-action-icon h-4 w-4"
                                        onClick={() =>
                                          handleEditMessage(message.id)
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="action-buttons-container">
                              <Tooltip title="Insert in editor" arrow>
                                <span>
                                  <PlusIcon
                                    className="chat-action-icon h-4 w-4"
                                    onClick={() =>
                                      handleInsertIntoEditor(message)
                                    }
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title="Like" arrow>
                                <span>
                                  <ThumbUpIcon
                                    className="chat-action-icon h-4 w-4"
                                    onClick={() => handleRate(message, 'up')}
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title="Dislike" arrow>
                                <span>
                                  <ThumbDownIcon
                                    className="chat-action-icon h-4 w-4"
                                    onClick={() => handleRate(message, 'down')}
                                  />
                                </span>
                              </Tooltip>
                              <Tooltip title="Copy" arrow>
                                <span>
                                  <DocumentDuplicateIcon
                                    className="chat-action-icon h-4 w-4"
                                    onClick={() => handleCopy(message.content)}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center h-full px-4">
                  <div
                    className="bg-gray-50 rounded-lg p-6 shadow-sm border border-gray-200 text-center w-full max-w-xs"
                    style={{ maxWidth: '280px' }}
                  >
                    <div className="mx-auto h-12 w-12 flex items-center justify-center rounded-full bg-purple-100 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-purple-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                        />
                      </svg>
                    </div>
                    <h3 className="text-gray-900 font-medium text-base mb-2">
                      No messages yet
                    </h3>
                    <p className="text-gray-500 text-sm">
                      Ask me about your content or how I can help with your blog
                      post.
                    </p>
                    <div className="mt-4 text-xs text-gray-400">
                      Try asking about SEO, content ideas, or editing
                      suggestions
                    </div>
                  </div>
                </div>
              )}

              {/* AI thinking/loading indicator */}
              {isLoading && (
                <div className="chat-message chat-message-bot">
                  <img
                    src={novaIcon}
                    alt="AI Avatar"
                    className="w-8 h-8 rounded-full"
                  />
                  <div className="chat-message-content">
                    <div
                      className="message-content-wrapper max-w-full overflow-hidden flex items-center"
                      style={{
                        width: '260px',
                        maxWidth: '260px',
                        minHeight: '30px',
                      }}
                    >
                      <div className="typing-indicator">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Scroll to top button */}
              {showScrollButton && (
                <button
                  onClick={scrollToTop}
                  className="scroll-to-top-button"
                  aria-label="Scroll to top"
                >
                  <ChevronDownIcon className="h-5 w-5 text-gray-500 transform rotate-180" />
                </button>
              )}
            </div>

            {/* Chat input area */}
            <div
              className="chat-input-container"
              style={{ width: '100%', padding: '10px 15px' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <textarea
                  ref={textareaRef}
                  className="chat-input"
                  placeholder="Type a message..."
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  onPaste={() => {
                    // Adjust height after paste event is processed
                    setTimeout(adjustTextareaHeight, 0);
                  }}
                  onInput={adjustTextareaHeight}
                  onFocus={adjustTextareaHeight}
                  disabled={isLoading}
                  rows="1"
                  style={{
                    minHeight: '38px',
                    maxHeight: '110px',
                    width: 'calc(100% - 0px)',
                    margin: '0',
                    padding: '8px 55px 8px 12px',
                    borderRadius: '6px',
                    border: '1px solid #e5e7eb',
                    fontSize: '14px',
                    outline: 'none',
                    resize: 'none',
                    lineHeight: '1.5',
                    boxSizing: 'border-box',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                />
                <div
                  className="send-button-visible"
                  onClick={() => {
                    if (!isLoading && inputText.trim()) {
                      handleSendMessage();
                    }
                  }}
                  style={{
                    width: '30px',
                    height: '30px',
                    marginLeft: '-38px',
                    backgroundColor: inputText.trim() ? '#8b5cf6' : '#e1dafe',
                    color: inputText.trim() ? 'white' : '#9ca3af',
                    borderRadius: '50%',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: inputText.trim() ? 'pointer' : 'default',
                    boxShadow: inputText.trim()
                      ? '0 2px 4px rgba(0,0,0,0.2)'
                      : 'none',
                    position: 'relative',
                    zIndex: 999,
                  }}
                >
                  <PaperAirplaneIcon
                    className="h-5 w-5"
                    style={{
                      transform: 'rotate(90deg)',
                      marginLeft: '2px',
                      marginTop: '-2px',
                      color: 'currentColor',
                    }}
                  />
                </div>
              </div>

              {/* Make feature buttons container full width */}
              <div
                className="chat-feature-buttons"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '5px 0',
                  width: '100%',
                  borderTop: '1px solid rgba(229, 231, 235, 0.5)',
                  marginTop: '8px',
                }}
              >
                <div className="flex items-center" style={{ gap: '6px' }}>
                  <Tooltip title="Voice input" arrow>
                    <button
                      onClick={toggleVoiceRecording}
                      className={`feature-button ${
                        isVoiceRecording ? 'recording' : ''
                      }`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        padding: '2px 6px',
                        backgroundColor: '#f3f4f6',
                        borderRadius: '4px',
                        fontSize: '10px',
                        height: '20px',
                      }}
                    >
                      <MicrophoneIcon
                        style={{ width: '10px', height: '10px' }}
                      />
                      <span style={{ fontSize: '10px' }}>
                        {isVoiceRecording ? 'Listening...' : 'Voice'}
                      </span>
                    </button>
                  </Tooltip>

                  <Tooltip title="Improve your prompt" arrow>
                    <button
                      onClick={handleImprovePrompt}
                      className={`feature-button ${
                        isImprovingPrompt ? 'loading' : ''
                      }`}
                      disabled={isImprovingPrompt || isLoading}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        padding: '2px 6px',
                        backgroundColor: '#f3f4f6',
                        borderRadius: '4px',
                        fontSize: '10px',
                        height: '20px',
                      }}
                    >
                      {isImprovingPrompt ? (
                        <svg
                          style={{ width: '10px', height: '10px' }}
                          className="animate-spin text-purple-500"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <LightningBoltIcon
                          style={{ width: '10px', height: '10px' }}
                        />
                      )}
                      <span style={{ fontSize: '10px' }}>
                        {isImprovingPrompt ? 'Improving...' : 'Improve'}
                      </span>
                    </button>
                  </Tooltip>
                </div>

                <div className="flex items-center feature-buttons-right">
                  <Tooltip title="Analyze content from your editor" arrow>
                    <div
                      className="editor-toggle"
                      style={{
                        backgroundColor: '#f3f4f6',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        border: '1px solid #e5e7eb',
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        marginRight: '8px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11px',
                          fontWeight: 500,
                          color: '#4b5563',
                          marginRight: '4px',
                        }}
                      >
                        Look in editor
                      </span>
                      <Switch
                        checked={lookInTheEditor}
                        onChange={() =>
                          dispatch(setLookInTheEditor(!lookInTheEditor))
                        }
                        className={`${
                          lookInTheEditor ? 'bg-purple-600' : 'bg-gray-200'
                        } relative inline-flex h-4 w-8 items-center rounded-full ml-1`}
                      >
                        <span
                          className={`${
                            lookInTheEditor
                              ? 'translate-x-5'
                              : 'translate-x-0.5'
                          } inline-block h-3 w-3 rounded-full bg-white transition`}
                        />
                      </Switch>
                    </div>
                  </Tooltip>

                  <Tooltip title="Web, SEO, Analyze" arrow>
                    <div
                      className="ai-agent-toggle"
                      style={{
                        backgroundColor: '#f3f4f6',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        border: '1px solid #e5e7eb',
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        position: 'relative',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '11px',
                          fontWeight: 500,
                          color: '#4b5563',
                          marginRight: '4px',
                        }}
                      >
                        AI Agent
                      </span>
                      <Switch
                        checked={connectToTheWeb}
                        onChange={toggleConnectToTheWeb}
                        disabled={!hasAIAgentAccess}
                        className={`${
                          connectToTheWeb ? 'bg-purple-600' : 'bg-gray-200'
                        } relative inline-flex h-4 w-8 items-center rounded-full ml-1`}
                      >
                        <span
                          className={`${
                            connectToTheWeb
                              ? 'translate-x-5'
                              : 'translate-x-0.5'
                          } inline-block h-3 w-3 rounded-full bg-white transition`}
                        />
                      </Switch>
                      {!hasAIAgentAccess && (
                        <div
                          className="text-xs text-gray-500 absolute"
                          style={{
                            bottom: '-15px',
                            right: '0px',
                            fontSize: '9px',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Upgrade plan to use
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChatPanel;
