import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Chat.scss';
import ChatInput from '../pages/Chat/ChatInput';
import NewHeader from '../components/NewHeader';
import NewWrapper from '../components/NewWrapper';
import {
  setActiveChatId,
  setChatMessage,
  setMessages,
  setShowScrollBottomButton,
} from '../store/actions/chat.actions';
import ChatMessages from './Chat/ChatMessages';
import { useGetChatMessages } from '../utils/chatApi';
import { useParams } from 'react-router-dom';
import ChatScrollButton from './Chat/ChatScrollButton';

const Chat = ({ id, editorRef, isInBuilder = false }) => {
  const { getChatMessages } = useGetChatMessages();

  const isPanelMode = useSelector(state => state.chat.isPanelMode);
  const activeChatId = useSelector(state => state.chat.activeChatId);
  const { chatId: routeChatId } = useParams();
  const chatId = routeChatId || activeChatId;

  const chatMessageRef = useRef(null);
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    resetChatState();

    return () => {
      resetChatState();
    };
  }, []);

  useEffect(() => {
    dispatch(setActiveChatId(routeChatId));
    dispatch(setShowScrollBottomButton(false));
  }, [routeChatId]);

  useEffect(() => {
    if (chatId) {
      const fetchChatMessages = async () => {
        const chatMessages = await getChatMessages(chatId);
        dispatch(setMessages(chatMessages || []));
      };

      fetchChatMessages();
    }
  }, [chatId]);

  const handleScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const isNotAtBottom = scrollTop + clientHeight < scrollHeight;
    dispatch(setShowScrollBottomButton(isNotAtBottom));
  };

  const handleScrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      dispatch(setShowScrollBottomButton(false));
    }
  };

  const resetChatState = () => {
    dispatch(setChatMessage(''));
    dispatch(setActiveChatId(''));
    dispatch(setMessages([]));
  };

  const ChatContent = () => (
    <div
      className={`chat-container ${
        isPanelMode ? 'w-[400px] panel' : 'w-full '
      } ${isInBuilder ? 'in-builder' : ''}`}
      style={isInBuilder ? { height: '100%' } : {}}
    >
      <div
        className={`chat-main ${isInBuilder ? 'builder-chat-main' : ''}`}
        style={
          isInBuilder
            ? { height: '100%', display: 'flex', flexDirection: 'column' }
            : {}
        }
      >
        <div
          className={`chat-messages ${isInBuilder ? 'builder-messages' : ''}`}
          onScroll={handleScroll}
          style={
            isInBuilder ? { flex: '1', overflowY: 'auto', minHeight: '0' } : {}
          }
        >
          <ChatMessages
            chatId={chatId}
            editorRef={editorRef}
            handleScrollToBottom={handleScrollToBottom}
            chatMessageRef={chatMessageRef}
            messagesEndRef={messagesEndRef}
            isInBuilder={isInBuilder}
          />
          <div ref={messagesEndRef}></div>
          <ChatScrollButton handleScrollToBottom={handleScrollToBottom} />
        </div>
        <ChatInput
          chatId={chatId}
          editorRef={editorRef}
          isInBuilder={isInBuilder}
        />
      </div>
    </div>
  );

  return isPanelMode ? (
    <ChatContent />
  ) : (
    <NewWrapper header={<NewHeader />}>
      <ChatContent />
    </NewWrapper>
  );
};

export default Chat;
