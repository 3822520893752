import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import {
  PlusIcon,
  ViewListIcon,
  ViewGridIcon,
  LightningBoltIcon,
  TrashIcon,
  ChipIcon,
  LightBulbIcon,
  ChartSquareBarIcon,
  DesktopComputerIcon,
  PlusSmIcon,
  CollectionIcon,
} from '@heroicons/react/outline';
import layoutView from '../store/actions/layout.actions';
import CreateProjectSlideOver from '../pages/NewProject/CreateProjectSlideOver';
import SemrushLogo from '../styles/images/logo-semrush.svg';
import { Checkbox } from '@material-ui/core';
import { set } from 'react-ga';
// import { TrashIcon } from '@heroicons/react/solid';
import useSentData from '../services/useSentData';
import { getCategory } from './dashboard/_utils';
import DeletePostModal from '../pages/Dashboard/DeletePostModal';

function PageHeading({
  category,
  title,
  gridView,
  setGridView = () => {},
  selectedProjectId,
  isListView,
  isShowAnalyticDashboard,
  setShowAnalyticDashboard = () => {},
  layoutView,
  user,
  beta,
  showSemrushBadge = false,
  isBulkSelect,
  setIsBulkSelect = () => null,
  onBulkDelete = () => null,
  selectedItems = [],
  actionButton = null, // Add this line
}) {
  const { project_id } = useParams();
  const [open, setOpen] = useState(false);
  const [openDeleteModal, showDeleteModal] = useState(false);
  const request = useSentData();
  const hasBetaAccess = !!user?.beta_access;
  const isButtonDisabled =
    user?.app_sumo_subscription &&
    !user?.blog_booster_enabled &&
    !user?.blog_booster_access;

  const onChangeBulkSelect = e => setIsBulkSelect(e.target.checked);

  const onClickBulkDelete = () => {
    request
      .send(
        `/api/projects/${project_id}/delete-blogs`,
        {
          postIds: selectedItems,
        },
        'post'
      )
      .then(data => {
        if (!data.isAxiosError) {
          onBulkDelete();
          showDeleteModal(false);
        }
      });
  };

  const renderWritingAssistantButtons = () => {
    if (
      category === 'post' &&
      (user.is_owner || user.is_manager || user.is_editor)
    ) {
      return (
        <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <Link
            to={`/project/${selectedProjectId ||
              project_id}/generate-first-draft-pro`}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out"
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-green-500 rounded-md p-2">
                  <ChipIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </div>
                <div className="ml-4 flex-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    First Draft PRO
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    Generate
                  </dd>
                </div>
              </div>
            </div>
          </Link>

          <Link
            to={`/project/${selectedProjectId ||
              project_id}/bulk-first-draft-pro`}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out relative"
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-indigo-500 rounded-md p-2">
                  <CollectionIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Bulk First Draft PRO
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    Generate Multiple
                  </dd>
                  <span className="absolute top-2 right-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-800">
                    Coming Soon
                  </span>
                </div>
              </div>
            </div>
          </Link>

          <Link
            to={`/project/${selectedProjectId ||
              project_id}/blog-post-improver`}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out"
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-purple-500 rounded-md p-2">
                  <LightBulbIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Blog Booster
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    Improve
                  </dd>
                </div>
              </div>
            </div>
          </Link>

          <Link
            to={`/project/${selectedProjectId ||
              project_id}/generate-first-draft`}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out"
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-yellow-500 rounded-md p-2">
                  <LightningBoltIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Quick Draft
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    Create
                  </dd>
                </div>
              </div>
            </div>
          </Link>

          <Link
            to={{
              pathname: selectedProjectId
                ? `/project/${selectedProjectId}/keyword-research-new`
                : '/keyword-research-new',
              state: { page: 'post' },
            }}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out"
          >
            <div className="p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-blue-500 rounded-md p-2">
                  <PlusSmIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    New Post
                  </dt>
                  <dd className="text-base font-medium text-gray-900">Start</dd>
                </div>
              </div>
            </div>
          </Link>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <CreateProjectSlideOver
        open={open}
        setOpen={() => {
          setOpen(false);
        }}
      />
      <DeletePostModal
        projectId={project_id}
        category={category}
        open={openDeleteModal}
        isBulkSelect={isBulkSelect}
        setOpen={() => showDeleteModal(false)}
        onBulkDelete={onClickBulkDelete}
      />
      <div className="max-w-full mx-auto">
        <div className="px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {title}
              {beta && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                  Beta
                </span>
              )}
              {showSemrushBadge && (
                <span className="ml-2 inline-flex items-center">
                  <img src={SemrushLogo} className="h-6" alt="Semrush logo" />
                </span>
              )}
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            {(user.is_owner || user.is_manager || user.is_editor) &&
              actionButton}
            {(user.is_owner || user.is_manager || user.is_editor) &&
              actionButton && <div className="ml-3"></div>}
            {(category === 'post' ||
              category === 'title' ||
              category === 'outline' ||
              category === 'rewrite' ||
              category === 'summary' ||
              category === 'keyword' ||
              category === 'social') && (
              <div className="flex items-center space-x-2">
                {isBulkSelect && selectedItems.length > 0 && (
                  <button
                    onClick={() => showDeleteModal(true)}
                    className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                )}
                <button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                  <Checkbox
                    checked={isBulkSelect}
                    color="primary"
                    onChange={onChangeBulkSelect}
                    className={'custom-checkbox'}
                  />
                  <span className="ml-2 text-gray-700">Bulk Select</span>
                </button>
              </div>
            )}
            {category === 'dashboard' && (
              <Tooltip
                title={`${
                  isShowAnalyticDashboard ? 'Overview' : 'Analytics'
                } Dashboard`}
                placement="bottom"
                arrow
              >
                <button
                  type="button"
                  onClick={() =>
                    setShowAnalyticDashboard(!isShowAnalyticDashboard)
                  }
                  className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  {isShowAnalyticDashboard ? (
                    <DesktopComputerIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChartSquareBarIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </Tooltip>
            )}
            {(category === 'post' ||
              category === 'title' ||
              category === 'outline' ||
              category === 'keyword' ||
              category === 'rewrite' ||
              category === 'summary' ||
              category === 'dashboard') &&
              !isShowAnalyticDashboard && (
                <Tooltip
                  title="Grid or list view toggle"
                  placement="bottom"
                  arrow
                >
                  <button
                    type="button"
                    onClick={() => {
                      layoutView(!isListView);
                      setGridView(!gridView);
                    }}
                    className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    {!isListView ? (
                      <ViewListIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ViewGridIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </button>
                </Tooltip>
              )}
          </div>
        </div>
        <div className="mt-6 px-4 sm:px-6 lg:px-8">
          {renderWritingAssistantButtons()}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    isListView: state.layout.isListView,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, { layoutView: layoutView })(
  PageHeading
);
