import React, { useState, Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useHistory, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import classNames from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/solid';

import DeletePostModal from './DeletePostModal';
import MovePostModal from './MovePostModal';
import UpdatePostStatusModal from './UpdateStatusModel';
import TableContainer from '../../components/table/table-container';
import Table from '../../components/table/table';
import TableDataRow from '../../components/table/table-data-row';
import TableDataCell from '../../components/table/table-data-cell';
import TablePagination from '../../components/table/table-pagination';

import noImage from '../../styles/images/bramework-no-image.svg';
import store from '../../store';
import { Checkbox } from '@material-ui/core';

const headers = [
  'Featured Image',
  'Title',
  'Keyword',
  'Status',
  'Last Edit',
  '',
];

function DraftsList({
  posts,
  callback,
  response,
  postStatuses = [],
  onToggleSelectItem,
  isBulkSelect,
  selectedItems,
  onToggleSelectAllItems,
}) {
  let history = useHistory();
  const state = store.getState();
  const user = state.auth.user;
  const [openDeleteModal, showDeleteModal] = useState(false);
  const [openMoveModal, showMoveModal] = useState(false);
  const [openStatusModal, showStatusModal] = useState(false);
  const [postData, setPostData] = useState({});
  const { project_id } = useParams();

  function closeMoveModal(moved) {
    if (moved) {
      callback();
    }
    showMoveModal(false);
    setPostData({});
  }

  function closeStatusModal(updated) {
    if (updated) {
      callback();
    }
    showStatusModal(false);
    setPostData({});
  }

  function closeDeleteModal(deleted) {
    if (deleted) {
      callback();
    }
    showDeleteModal(false);
    setPostData({});
  }

  function showDetails(id) {
    history.push(`/project/${project_id}/post-builder/${id}`);
  }

  const onChangeSelected = (id, e) => {
    e.preventDefault();
    onToggleSelectItem(id, e.target.checked);
  };

  function renderTableData() {
    return posts.map((post, index) => {
      const {
        id,
        phrase,
        category,
        edited_at,
        body,
        published,
        status,
        first_draft_completed,
      } = post;
      const fullTitle =
        category && body && body[0].state.text
          ? body[0].state.text
          : category && body && !body[0].state.text
          ? '(no title)'
          : phrase;
      // Truncate the title to 50 characters
      const title =
        fullTitle.length > 60 ? fullTitle.substring(0, 60) + '...' : fullTitle;
      // Truncate the keyword (phrase) to 30 characters
      const truncatedPhrase =
        phrase.length > 30 ? phrase.substring(0, 30) + '...' : phrase;
      const thumbnail =
        category && body && body[1].state.file
          ? body[1].state.file.thumbnail
          : noImage;

      const postStatus = postStatuses.find(stat => stat.id === status);

      return (
        <TableDataRow
          key={id}
          className={`cursor-pointer ${
            !first_draft_completed ? 'opacity-50 pointer-events-none' : ''
          }`}
        >
          {/* Checkboxes */}
          {isBulkSelect && (
            <TableDataCell>
              <Checkbox
                checked={selectedItems.findIndex(item => item === id) >= 0}
                onChange={e => onChangeSelected(id, e)}
                className="custom-checkbox dashboard-item-checkbox"
              />
            </TableDataCell>
          )}
          <TableDataCell>
            <div className="flex items-center">
              <div>
                <img
                  className="w-16 rounded-md object-cover"
                  src={thumbnail}
                  alt=""
                />
              </div>
            </div>
          </TableDataCell>
          <TableDataCell onClick={() => showDetails(id)}>{title}</TableDataCell>
          <TableDataCell onClick={() => showDetails(id)}>
            {truncatedPhrase}
          </TableDataCell>
          <TableDataCell
            onClick={() => showDetails(id)}
            className={
              postStatus?.status === 'Published' ? 'text-bw-green' : ''
            }
          >
            {!post.first_draft_completed
              ? 'Writing Draft'
              : postStatus?.status || 'No status'}
          </TableDataCell>
          <TableDataCell
            className="text-gray-500"
            onClick={() => showDetails(id)}
          >
            <Moment fromNow>{edited_at}</Moment>
          </TableDataCell>
          <TableDataCell className="text-right">
            {!post.first_draft_completed ? (
              <div class="inline-flex items-center justify-center">
                <div class="loader animate-spin rounded-full h-6 w-6 border-t-2 border-transparent border-b-2 border-gray-400"></div>
              </div>
            ) : (
              <Menu as="div" className="static inline-block text-left">
                <div>
                  <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="z-10 origin-top-right absolute right-14 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              history.push(
                                `/project/${project_id}/post-preview/${id}`
                              );
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Preview
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              history.push(
                                `/project/${project_id}/keyword-research-new/${id}`,
                                { page: 'analyzer' }
                              );
                            }}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Analyzer
                          </button>
                        )}
                      </Menu.Item>
                      {(user.is_owner || user.is_manager) && (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setPostData({ id: id, name: title });
                                  showMoveModal(true);
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                                Move
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setPostData({ id: id, name: title, status });
                                  showStatusModal(true);
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                                Status
                              </button>
                            )}
                          </Menu.Item>
                        </>
                      )}
                      {(user.is_owner || user.is_manager || user.is_editor) && (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                setPostData({ id: id, name: title });
                                showDeleteModal(true);
                              }}
                              className={classNames(
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              Delete
                            </button>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </TableDataCell>
        </TableDataRow>
      );
    });
  }

  return (
    <div>
      <DeletePostModal
        id={postData.id}
        projectId={project_id}
        name={postData.name}
        category={'post'}
        open={openDeleteModal}
        setOpen={closeDeleteModal}
      />
      <MovePostModal
        id={postData.id}
        projectId={project_id}
        name={postData.name}
        category={'post'}
        open={openMoveModal}
        setOpen={closeMoveModal}
      />
      <UpdatePostStatusModal
        postId={postData.id}
        projectId={project_id}
        open={openStatusModal}
        setOpen={closeStatusModal}
        currentStatus={postData.status}
        onUpdated={() => closeStatusModal(true)}
      />
      <div className="flex flex-col">
        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <TableContainer>
            <Table
              headers={headers}
              isSelection={isBulkSelect}
              dataLength={posts.length}
              selectedItemsLength={selectedItems.length}
              onToggleSelectAll={onToggleSelectAllItems}
              renderData={renderTableData}
            />
            <TablePagination
              startIndex={(response.current_page - 1) * response.page_size + 1}
              endIndex={
                response.current_page * response.page_size < response.count
                  ? response.current_page * response.page_size
                  : response.count
              }
              total={response.count}
              isPreviousDisabled={!response.previous_page}
              isNextDisabled={!response.next_page}
              onPrevious={() => callback(response.previous_page)}
              onNext={() => callback(response.next_page)}
            />
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default DraftsList;
