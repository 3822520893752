import React from 'react';
import './TrendItem.scss';
import { formatNumber } from '../../utils/common';

export default function Trend({ volume, trend }) {
  let color;
  if (trend.trend > 80) {
    color = '#24CF33';
  } else if (trend.trend > 60) {
    color = '#FB5607';
  } else {
    color = '#FF006E';
  }

  const value = trend.search_volume || (volume * trend.trend) / 100;

  // Create tooltip with year only if it exists
  const tip = trend.year
    ? `<strong>${trend.month} ${
        trend.year
      }</strong><br/>Monthly Volume<div class="trend-tip-value">${formatNumber(
        value,
        1
      )}</div>`
    : `<strong>${
        trend.month
      }</strong><br/>Monthly Volume<div class="trend-tip-value">${formatNumber(
        value,
        1
      )}</div>`;

  // Format year as last two digits for display, only if year exists
  const shortYear = trend.year ? trend.year.toString().slice(-2) : null;

  return (
    <div className="trend-component">
      <div className="column-value">
        <div
          data-tip={tip}
          style={{
            height: `${trend.trend}%`,
            background: color,
          }}
        />
      </div>
      <div className="column-name">
        {trend.month}
        {shortYear && (
          <span className="text-xs text-gray-500">'{shortYear}</span>
        )}
      </div>
    </div>
  );
}
