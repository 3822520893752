// Get auth token from localStorage
export const getAuthToken = () => {
  return localStorage.getItem('access_token');
};

// Set auth token in localStorage
export const setAuthToken = token => {
  localStorage.setItem('access_token', token);
};

// Remove auth token from localStorage
export const removeAuthToken = () => {
  localStorage.removeItem('access_token');
};

// Check if user is authenticated
export const isAuthenticated = () => {
  return !!getAuthToken();
};
