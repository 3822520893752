import React, { useEffect } from 'react';
import ChatAccordion from './ChatAccordion';
import { PlusSmIcon } from '@heroicons/react/solid';
import '../../pages/Chat.scss';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadConversations,
  loadFavoritePrompts,
  setActiveChatId,
  setChatMessage,
  setMessages,
} from '../../store/actions/chat.actions';

const ChatLeftPanel = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleNewChatClick = () => {
    history.push(`/chat`);
    dispatch(setActiveChatId(''));
    dispatch(setMessages(''));
    dispatch(setChatMessage(''));
  };

  useEffect(() => {
    dispatch(loadFavoritePrompts());
    dispatch(loadConversations());
  }, []);
  const handleFavoritePromptClick = () => {};

  return (
    <div className="chat-left-panel">
      <div className="new-chat-button p-2" onClick={handleNewChatClick}>
        <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
        <span>New Chat</span>
      </div>
      <ChatAccordion
        onNewChat={handleNewChatClick}
        onFavoritePromptClick={handleFavoritePromptClick}
      />
    </div>
  );
};

export default ChatLeftPanel;
